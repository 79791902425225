import { MeshBasicMaterialProps, MeshProps, Object3DProps, useLoader } from '@react-three/fiber'
import { forwardRef, ForwardRefExoticComponent, Fragment, PropsWithoutRef, RefAttributes, useMemo } from 'react'
import { Box3, DoubleSide, Object3D, Vector2, Shape, ShapeGeometry, ColorRepresentation } from 'three'
import { SVGLoader } from 'three-stdlib'
import { Line } from '@react-three/drei'
import { Style } from '../../gql/graphql';
import { floorplannerStore } from '../../store/floorplannerStore'

type ForwardRefComponent<P, T> = ForwardRefExoticComponent<PropsWithoutRef<P> & RefAttributes<T>>

export interface SvgProps extends Omit<Object3DProps, 'ref'> {
  /** src can be a URL or SVG data */
  svgId: string,
  src: string
  skipFill?: boolean
  skipStrokes?: boolean
  fillMaterial?: MeshBasicMaterialProps
  strokeMaterial?: MeshBasicMaterialProps
  fillMeshProps?: MeshProps
  strokeMeshProps?: MeshProps
  lineWeight?: number
  lineColor?: ColorRepresentation
  width?: number
  lineType?: string
}

export const Svg: ForwardRefComponent<SvgProps, Object3D> = forwardRef<Object3D, SvgProps>(
  function R3FSvg(
    {svgId,
      src,
      skipFill = false,
      skipStrokes = false,
      fillMaterial,
      strokeMaterial,
      fillMeshProps,
      strokeMeshProps,
      lineWeight = 1,
      lineColor = 'black',
      lineType = 'solid',
      width,
      ...props
    },
    ref
  ) {
    const svg = useLoader(SVGLoader, !src.startsWith('<svg') ? src : `data:image/svg+xml;utf8,${src}`)
    const noopRaycast = () => null;

    // Calculate stroke geometries (Vector2 points) for the lines
    const strokeGeometries = useMemo(
      () =>
        skipStrokes
          ? []
          : svg.paths.map((path) => {
            if (path.userData?.style.stroke === undefined || path.userData.style.stroke === 'none') return null;
            const subPathsPoints = path.subPaths.map((subPath) => subPath.getPoints());
            return subPathsPoints;
          }),
      [svg, skipStrokes]
    )

    // Compute the bounding box using the path points
    const { minX, minY, maxX, maxY } = useMemo(() => {
      let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity

      svg.paths.forEach((path) => {
        path.subPaths.forEach((subPath) => {
          subPath.getPoints().forEach((point) => {
            if (point.x < minX) minX = point.x
            if (point.x > maxX) maxX = point.x
            if (point.y < minY) minY = point.y
            if (point.y > maxY) maxY = point.y
          })
        })
      })
      floorplannerStore.updateSymbolProperty(svgId, "svgRatio", (maxX - minX)/(maxY - minY));
      return { minX, minY, maxX, maxY }
    }, [svg])

    // **Add this condition to prevent rendering before calculations are complete**
    const calculationsReady = isFinite(minX) && isFinite(minY) && isFinite(maxX) && isFinite(maxY);
    if (!calculationsReady) {
      return null;
    }

    // Calculate the actual width and height of the SVG based on the min/max points
    const actualWidth = maxX - minX
    const actualHeight = maxY - minY
    const aspectRatio = actualWidth / actualHeight

    // Compute separate scale factors for width and height
    const scaleFactorX = width ? width / actualWidth : 1
    const scaleFactorY = width ? (width / aspectRatio) / actualHeight : 1

    const targetWidth = width || actualWidth
    const targetHeight = width ? width / aspectRatio : actualHeight

    // Function to scale the points for lines
    const scalePoints = (points: Vector2[]) => {
      return points.map(point => new Vector2(
        (point.x - minX) * scaleFactorX, // Scale and translate x
        (point.y - minY) * scaleFactorY  // Scale and translate y
      ))
    }

    // Function to scale the shape geometry by creating a new scaled shape
    const scaleShape = (shape: Shape) => {
      const scaledShape = shape.clone()
      const points = scaledShape.getPoints().map(p => {
        return new Vector2(
          (p.x - minX) * scaleFactorX,
          (p.y - minY) * scaleFactorY
        )
      })

      // Create a new shape using the scaled points
      const newShape = new Shape(points)
      return newShape
    }

    let renderOrder = 0;

    return (
      <object3D ref={ref} {...props}>
        <object3D
          position={[-targetWidth / 2, -targetHeight / 2, 0]}
        >
          {svg.paths.map((path, p) => (
            <Fragment key={`svg${p}`}>
              {!skipFill &&
                path.userData?.style.fill !== undefined &&
                path.userData.style.fill !== 'none' &&
                SVGLoader.createShapes(path).map((shape, s) => (
                  <group key={s}>
                    <mesh {...fillMeshProps} renderOrder={renderOrder++}>
                      <shapeGeometry args={[scaleShape(shape)]} />
                      <meshBasicMaterial
                        color={path.userData!.style.fill}
                        opacity={path.userData!.style.fillOpacity}
                        side={DoubleSide}
                        depthWrite={true}
                        {...fillMaterial}
                      />
                    </mesh>
                  </group>
                ))}
              {!skipStrokes &&
                strokeGeometries[p] &&
                path.userData?.style.stroke !== undefined &&
                path.userData.style.stroke !== 'none' &&
                path.subPaths.map((_subPath, s) => (
                  <Line
                    key={s}
                    points={scalePoints(strokeGeometries[p]![s])} // Use the scaled points for the Line component
                    color={lineColor}
                    lineWidth={lineWeight}
                    opacity={path.userData!.style.strokeOpacity}
                    renderOrder={renderOrder++}
                    {...(lineType === "dashed" && {
                      dashed: true,
                      dashSize: 0.15,
                      gapSize: 0.1,
                    })}
                    raycast={noopRaycast}
                  />
                ))}
            </Fragment>
          ))}
        </object3D>
      </object3D>
    )
  }
)
