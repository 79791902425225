import { computed, makeAutoObservable, observable, transaction } from "mobx";
import React, { useRef } from "react";
import { useContext } from "react";
import { WallType, SymbolType, isSymbolType, SingleLineType, isSingleLineType, isWallType, RulerLineType, isRulerLineType } from '../types/wallTypes';
import { floorplannerStore } from "./floorplannerStore";
import { ApolloClient } from "@apollo/client";
import * as THREE from "three";
import { invalidate } from "@react-three/fiber";
import { symbol } from 'prop-types';
import * as polygonClipping from 'polygon-clipping';

class EditorStore {
  client: ApolloClient<any> | undefined;
  floorplannerStore = floorplannerStore;
  view3D = false;
  selections = Array<WallType | SymbolType | SingleLineType | RulerLineType>();
  wallEditingOuterLength = undefined as string | undefined;
  wallEditingInnerLength = undefined as string | undefined;
  wallEditingLengthPanel = undefined as number | undefined;
  singleLineEditingLength = undefined as string | undefined;
  singleLineEditingLengthPanel = undefined as number | undefined;
  rulerLineEditingLength = undefined as string | undefined;
  areaEditingLength = undefined as string | undefined;
  rulerEditingLengthPanel = undefined as number | undefined;
  textEditing = undefined as string | undefined;
  wallShowLength = true;
  textShow = true;
  showGrid = true;
  minimized = false;
  canUndo = false;
  canRedo = false;
  clipboard: Array<WallType | SymbolType | SingleLineType | RulerLineType> = [];
  pasteOffset = new THREE.Vector2(0.2, 0.2);
  lastPastePosition: THREE.Vector2 | null = null;
  @observable.ref camera: THREE.PerspectiveCamera | THREE.OrthographicCamera | null = null;
  zoomLevel = 1;
  wallConstructionMode = false;
  wallConstructionModeAddNewWall = false;
  lineConstructionMode = false;
  lineConstructionModeAddNewLine = false;
  rulerConstructionMode = false;
  rulerConstructionModeAddNewLine = false;
  areaConstructionMode = false;
  areaConstructionModeAddPoint = false;
  movementOffsetFine = 0.001;
  movementOffsetCorse = 0.01;
  panning = false;
  previousPanningState: boolean | null = null;
  groupRefsMap = new Map<string, THREE.Group | null>();
  isShiftPressed = false;
  wallDragging: string | null = null;
  lineDragging: string | null = null;
  rulerDragging: string | null = null;
  snappingTolerance = 0.1;
  whichEndToUpdate: "start" | "end" | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
    this.updateUndoRedoState(); // Initialize undo/redo state
    window.addEventListener("keydown", this.handleKeyDown);
    window.addEventListener("keyup", this.handleKeyUp);
    window.addEventListener("mousedown", this.handleMouseDown);
    window.addEventListener("mouseup", this.handleMouseUp);
  }

  dispose() {
    window.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("keyup", this.handleKeyUp);
    window.removeEventListener("mousedown", this.handleMouseDown);
    window.removeEventListener("mouseup", this.handleMouseUp);
  }

  initialize(client: ApolloClient<any>) {
    this.client = client;
  }

  setCamera(camera: THREE.PerspectiveCamera | THREE.OrthographicCamera) {
    this.camera = camera;
  }

  zoomIn = () => {
    if (this.camera) {
      this.zoomLevel = Math.min(this.zoomLevel + 0.05, 7); // Set max zoom level
      this.camera.zoom = this.zoomLevel;
      this.camera.updateProjectionMatrix();
      invalidate();
    }
  };

  zoomOut = () => {
    if (this.camera && this.camera.zoom > 0.1) {
      this.zoomLevel = Math.max(this.zoomLevel - 0.05, 0.2); // Set min zoom level
      this.camera.zoom = this.zoomLevel;
      this.camera.updateProjectionMatrix();
      invalidate();
    }
  };

  resetZoom = () => {
    if (this.camera) {
      this.zoomLevel = 1;
      this.camera.zoom = this.zoomLevel;
      this.camera.updateProjectionMatrix();
      // Reset panning
      this.camera.position.set(0, 0, 20);
      invalidate();
    }
  };

  zoomLevelDivisor = () => {
    // Return the divisor
    return this.zoomLevel / 1.4;
  }

  setPanning = (panning: boolean) => {
    this.panning = panning;
  }

  setShiftPressed = (pressed: boolean) => {
    this.isShiftPressed = pressed;
  }

  // Method to update undo/redo state
  updateUndoRedoState = () => {
    this.canUndo = this.floorplannerStore.undoStack.length > 0;
    this.canRedo = this.floorplannerStore.redoStack.length > 0;
  };

  // Undo action
  undo = () => {
    this.floorplannerStore.undo();
    this.updateUndoRedoState();
  };

  // Redo action
  redo = () => {
    this.floorplannerStore.redo();
    this.updateUndoRedoState();
  };

  copy = () => {
    // Copy selected elements to clipboard
    this.clipboard = this.selections.map(selection => ({ ...selection }));
    this.lastPastePosition = null;
  };

  // Paste action with position offset or at mouse position
  paste = () => {

    this.clipboard.forEach(item => {
      const itemPosition = item.type === "wall" || item.type === "singleLine" || item.type === "rulerLine" ? item.start : item.position;
      // Always paste pasteOffset away from the last paste position, but the first paste is at item.position + pasteOffset
      const newPosition = this.lastPastePosition ? this.lastPastePosition.clone().add(this.pasteOffset) : itemPosition.clone().add(this.pasteOffset);

      if (item.type === "wall") {
        this.floorplannerStore.cloneWall(item.id, newPosition);
      } else if (isSingleLineType(item)) {
        this.floorplannerStore.cloneLine(item.id, newPosition);
      }
      else if (isRulerLineType(item)) {
        this.floorplannerStore.cloneRuler(item.id, newPosition);
      } else if (isSymbolType(item)) {
        this.floorplannerStore.cloneSymbol(item.id, newPosition);
      }

      this.lastPastePosition = newPosition;
    });

  };

  duplicateSelected = () => {
    this.copy();
    this.paste();
  }

  bringToFront = () => {
    this.selections.forEach((selection) => {
      if (selection.type === "wall") {
        //this.floorplannerStore.bringWallToFront(selection.id);
      } else if (isSymbolType(selection)) {
        this.floorplannerStore.sendToFront(selection.id);
      }
    });
  }

  sendToBack = () => {
    this.selections.forEach((selection) => {
      if (selection.type === "wall") {
        //this.floorplannerStore.sendWallToBack(selection.id);
      } else if (isSymbolType(selection)) {
        this.floorplannerStore.sendToBack(selection.id);
      }
    });
  }

  bringForward = () => {
    this.selections.forEach((selection) => {
      if (selection.type === "wall") {
        //this.floorplannerStore.bringWallForward(selection.id);
      } else if (isSymbolType(selection)) {
        this.floorplannerStore.bringForward(selection.id);
      }
    });
  }

  bringBackward = () => {
    this.selections.forEach((selection) => {
      if (selection.type === "wall") {
        //this.floorplannerStore.sendWallBackward(selection.id);
      } else if (isSymbolType(selection)) {
        this.floorplannerStore.bringBackward(selection.id);
      }
    });
  }
  /*   addText = () => {
      this.selections.forEach((selection) => {
          if (selection.type === "text") {
            floorplannerStore.addSymbol("text", [0, 0]);
          } 
      });
    }
       */


  // Method to get the visible bounds based on the current camera position and zoom level

  getVisibleBounds = () => {
    if (!this.camera) return null;

    const aspectRatio = window.innerWidth / window.innerHeight;

    if (this.camera instanceof THREE.PerspectiveCamera) {
      // Handle Perspective Camera bounds calculation
      const halfFov = THREE.MathUtils.degToRad(this.camera.fov / 2); // Convert vertical FOV to radians
      const distance = Math.abs(this.camera.position.z); // Distance from camera to the scene origin (assuming z-axis is depth)
      const halfHeight = distance * Math.tan(halfFov); // Visible height at the camera's current position
      const halfWidth = halfHeight * aspectRatio;

      const visibleArea = {
        xMin: this.camera.position.x - halfWidth,
        xMax: this.camera.position.x + halfWidth,
        yMin: this.camera.position.y - halfHeight,
        yMax: this.camera.position.y + halfHeight,
      };

      return visibleArea;
    } else if (this.camera instanceof THREE.OrthographicCamera) {
      // Handle Orthographic Camera bounds calculation
      const halfWidth = (this.camera.right - this.camera.left) / 2 / this.camera.zoom;
      const halfHeight = (this.camera.top - this.camera.bottom) / 2 / this.camera.zoom;

      const visibleArea = {
        xMin: this.camera.position.x - halfWidth,
        xMax: this.camera.position.x + halfWidth,
        yMin: this.camera.position.y - halfHeight,
        yMax: this.camera.position.y + halfHeight,
      };

      return visibleArea;
    }

    return null; // If camera type is not handled
  };


  // Helper to calculate the center of the visible area
  getVisibleCenter = () => {
    const visibleBounds = this.getVisibleBounds();
    if (!visibleBounds) return [0, 0];

    const centerX = (visibleBounds.xMin + visibleBounds.xMax) / 2;
    const centerY = (visibleBounds.yMin + visibleBounds.yMax) / 2;
    return [centerX, centerY];
  };


  handleKeyDown = (event: KeyboardEvent) => {
    const activeElement = document.activeElement;
    const isInputField =
      activeElement?.tagName === "INPUT" ||
      activeElement?.tagName === "TEXTAREA" ||
      (activeElement?.getAttribute("contenteditable") === "true");

    // Always handle some keys regardless of input field focus
    if ((this.wallConstructionMode || editorStore.lineConstructionMode || editorStore.rulerConstructionMode || editorStore.areaConstructionMode) && event.key === "Escape") {
      event.preventDefault(); // Prevent any default behavior of the input field
      this.wallConstructionMode = false;
      this.lineConstructionMode = false;
      this.rulerConstructionMode = false;
      this.areaConstructionMode = false;
    } else if (this.wallConstructionMode && event.key === "Enter") {
      this.wallConstructionModeAddNewWall = true
    } else if (this.lineConstructionMode && event.key === "Enter") {
      this.lineConstructionModeAddNewLine = true
    } else if (this.rulerConstructionMode && event.key === "Enter") {
      this.rulerConstructionModeAddNewLine = true
    } 
    else if (this.areaConstructionMode && event.key === "Enter") {
      this.areaConstructionModeAddPoint = true
    }
    // Only handle other shortcuts if not focused on input field
    if (!isInputField) {
      if (event.ctrlKey || event.metaKey) {
        if (event.key === "z") {
          // Ctrl+Z or Cmd+Z
          event.preventDefault();
          this.undo();
        } else if (event.key === "y" || (event.shiftKey && event.key === "z")) {
          // Ctrl+Y or Ctrl+Shift+Z (or Cmd+Shift+Z on macOS)
          event.preventDefault();
          this.redo();
        } else if (event.key === "c") {
          // Ctrl+C or Cmd+C
          event.preventDefault();
          this.copy();
        } else if (event.key === "v") {
          // Ctrl+V or Cmd+V
          event.preventDefault();
          this.paste();
        }
      }

      // Handle zoom in and zoom out with keyboard shortcuts
      if (event.key === "=" || event.key === "+") {
        event.preventDefault();
        this.zoomIn();
      } else if (event.key === "-") {
        event.preventDefault();
        this.zoomOut();
      } else if (event.key === "0") {
        event.preventDefault();
        this.resetZoom();
      }

      // Handle movement with arrow keys
      let movementOffset
      if (event.shiftKey) {
        movementOffset = this.movementOffsetFine;
      } else {
        movementOffset = this.movementOffsetCorse;
      }
      if (event.key === "ArrowUp") {
        event.preventDefault();
        this.moveSelections(0, movementOffset); // Move up
      } else if (event.key === "ArrowDown") {
        event.preventDefault();
        this.moveSelections(0, -movementOffset); // Move down
      } else if (event.key === "ArrowLeft") {
        event.preventDefault();
        this.moveSelections(-movementOffset, 0); // Move left
      } else if (event.key === "ArrowRight") {
        event.preventDefault();
        this.moveSelections(movementOffset, 0); // Move right
      }

      if (event.key === 'Shift') this.setShiftPressed(true);

      // Construction mode shortcuts
      if (event.key === "w") {
        this.wallConstructionMode = !this.wallConstructionMode;
      } else if (event.key === "l") {
        this.lineConstructionMode = !this.lineConstructionMode;
      }
      else if (event.key === "r") {
        this.rulerConstructionMode = !this.rulerConstructionMode;
      }
      else if (event.key === "a") {
        this.areaConstructionMode = !this.areaConstructionMode;
      }
    }
  };

  handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Shift') this.setShiftPressed(false);
  }

  // Handle mouse wheel/trackpad zoom events
  handleWheel = (event: WheelEvent) => {
    if (this.camera && !this.view3D) {
      event.preventDefault(); // Prevent default scrolling behavior
      const delta = Math.sign(event.deltaY);

      if (delta > 0) {
        this.zoomOut(); // Zoom out if scrolling down or zooming out on trackpad
      } else if (delta < 0) {
        this.zoomIn(); // Zoom in if scrolling up or zooming in on trackpad
      }
    }
  };

  handleMouseDown = (event: MouseEvent) => {
    // Check if the third mouse button (middle mouse button) is pressed
    if (event.button === 1) {
      // Store the current panning state
      this.previousPanningState = this.panning;
      // Enable panning
      this.setPanning(true);
    }
  };

  handleMouseUp = (event: MouseEvent) => {
    // Check if the third mouse button (middle mouse button) is released
    if (event.button === 1 && this.previousPanningState !== null) {
      // Restore the previous panning state
      this.setPanning(this.previousPanningState);
      // Reset the previous panning state
      this.previousPanningState = null;
    }
  };

  setWallConstructionMode = (mode: boolean) => {
    this.wallConstructionMode = mode;
    if (mode) {
      this.lineConstructionMode = false;
      this.rulerConstructionMode = false;
      this.areaConstructionMode = false;
    }
  }

  setWallConstructionModeAddNewWall = (mode: boolean) => {
    this.wallConstructionModeAddNewWall = mode;
  }

  setLineConstructionMode = (mode: boolean) => {
    this.lineConstructionMode = mode;
    if (mode) {
      this.wallConstructionMode = false;
      this.rulerConstructionMode = false;
      this.areaConstructionMode = false;
    }
  }

  setLineConstructionModeAddNewLine = (mode: boolean) => {
    this.lineConstructionModeAddNewLine = mode;
  }
  setRulerConstructionMode = (mode: boolean) => {
    this.rulerConstructionMode = mode;
    if (mode) {
      this.wallConstructionMode = false;
      this.lineConstructionMode = false;
      this.areaConstructionMode = false;
      
    }
  }

  setRulerConstructionModeAddNewLine = (mode: boolean) => {
    this.rulerConstructionModeAddNewLine = mode;
  }

  setAreaConstructionMode = (mode: boolean) => {
    this.areaConstructionMode = mode;
    if (mode) {
      this.wallConstructionMode = false;
      this.rulerConstructionMode = false;
      this.lineConstructionMode = false;
    }
  }

  setAreaConstructionModeAddPoint = (mode: boolean) => {
    this.areaConstructionModeAddPoint = mode;
  }

  setWallEditingOuterLength = (wallId: string | undefined) => {
    this.wallEditingOuterLength = wallId
  }
  setWallEditingInnerLength = (wallId: string | undefined) => {
    this.wallEditingInnerLength = wallId
  }

  setWallEditingLengthPanel = (outerLength: number | undefined) => {
    this.wallEditingLengthPanel = outerLength
  }

  setWallShowLength = (show: boolean) => {
    this.wallShowLength = show;
  }
  setTextEditing = (text: string | undefined) => {
    this.textEditing = text
  }

  setSingleLineEditingLength = (length: string | undefined) => {
    this.singleLineEditingLength = length
  }

  setSingleLineEditingLengthPanel = (lineLength: number | undefined) => {
    this.singleLineEditingLengthPanel = lineLength
  }

  setRulerLineEditingLength = (length: string | undefined) => {
    this.rulerLineEditingLength = length
  }
  setAreaEditingLength = (length: string | undefined) => {
    this.areaEditingLength = length
  }

  setRulerEditingLengthPanel = (rulerLength: number | undefined) => {
    this.rulerEditingLengthPanel = rulerLength
  }

  setTextShow = (show: boolean) => {
    this.textShow = show;
  }

  setSelections = (selections: Array<WallType | SymbolType>) => {
    // Loop through selections and select them in the floorplanner
    floorplannerStore.unSelectAll();
    selections.forEach((selection) => {
      switch (selection.type) {
        case "wall":
          floorplannerStore.selectWall(selection.id);
          break;
        case "symbol":
        case "door":
        case "doubleDoor":
        case "window":
        case "circleStairs":
        case "rectStairs":
        case "square":
        case "circle":
        case "triangle":
        case "svg":
        case "text":
          floorplannerStore.selectSymbol(selection.id);
          break;
        default:
          console.error("Unknown selection type", selection);
      }
    });
    this.selections = selections;
  };

  clearSelections = () => {
    floorplannerStore.unSelectAll();
    this.selections = [];
  };

  addSelection = (selection: WallType | SymbolType | SingleLineType | RulerLineType) => {
    this.selections.push(selection);
  };

  removeSelection = (selection: WallType | SymbolType | RulerLineType) => {
    const index = this.selections.findIndex((s) => s.id === selection.id);
    if (index > -1) {
      this.selections.splice(index, 1);
    }
  };

  // Method to move selected elements by a given offset
  moveSelections = (xOffset: number, yOffset: number) => {
    transaction(() => {
      this.selections.forEach((selection) => {
        if (isWallType(selection) || isSingleLineType(selection) || isRulerLineType(selection)) {
          // Move the wall by updating its positions
          const start = new THREE.Vector2(selection.start.x + xOffset, selection.start.y + yOffset);
          const end = new THREE.Vector2(selection.end.x + xOffset, selection.end.y + yOffset);
          const controlPoint = selection.controlPoint ? new THREE.Vector2(selection.controlPoint.x + xOffset, selection.controlPoint.y + yOffset) : undefined;
          this.floorplannerStore.updateObjectPosition(selection.id, start, end);
          this.floorplannerStore.setObjectProperty(selection.id, "controlPoint", controlPoint);
        } else if (isSymbolType(selection)) {
          // Move the symbol by updating its position
          const symbol = this.floorplannerStore.symbolsMap.get(selection.id);
          if (symbol) {
            const posX = symbol.position.x + xOffset;
            const posY = symbol.position.y + yOffset;
            this.floorplannerStore.updateSymbolProperty(selection.id, "position", [
              posX,
              posY,
            ]);
          }
        }
      });
    });

    invalidate(); // Trigger re-render to reflect the changes
  };

  setView3D = (view3D: boolean) => {
    this.view3D = view3D;
  };

  setShowGrid = (showGrid: boolean) => {
    this.showGrid = showGrid;
  };

  setMinimized = (minimized: boolean) => {
    this.minimized = minimized;
  };

  // to delete the selection with Backspace
  delete = () => {
    this.selections.forEach((selection) => {
      if (selection.type === "wall") {
        this.floorplannerStore.removeWall(selection.id);
      } else if (isSymbolType(selection)) {
        this.floorplannerStore.removeSymbol(selection.id);
      } else if (isSingleLineType(selection)) {
        this.floorplannerStore.removeSingleLine(selection.id);
      } else if (isRulerLineType(selection)) {
        this.floorplannerStore.removeRulerLine(selection.id);
      }
    });
    this.clearSelections();
    this.updateUndoRedoState();
  };

  updateGroupRef = (id: string, groupRef: THREE.Group | null) => {
    this.groupRefsMap.set(id, groupRef);
  }

  setWallDragging = (dragging: string | null) => {
    this.wallDragging = dragging;
  }

  setLineDragging = (dragging: string | null) => {
    this.lineDragging = dragging;
  }

  setRulerDragging = (dragging: string | null) => {
    this.rulerDragging = dragging;
  }

  setSnappingTolerance = (tolerance: number) => {
    this.snappingTolerance = tolerance;
  }

  setWhichEndToUpdate = (end: "start" | "end" | undefined) => {
    this.whichEndToUpdate = end;
  }

}

export const editorStore = new EditorStore();
export const editorStoreContext = React.createContext<EditorStore>(editorStore);
export const useEditorStore = () => useContext(editorStoreContext);
