import React, { useRef } from 'react';
import { useThree, invalidate } from '@react-three/fiber';
import * as THREE from 'three';
import { editorStore } from '../../store/editorStore';

export const MAX_PLANE_SIZE = 300;

const PanningPlane: React.FC = () => {
    const { camera, viewport } = useThree();
    const isPanning = useRef(false);
    const startPoint = useRef<THREE.Vector2>(new THREE.Vector2());
    const cameraStart = useRef<THREE.Vector3>(new THREE.Vector3());

    const handlePointerDown = (event: PointerEvent) => {
        // Check if the middle mouse button is pressed
        if (event.button === 1 && !editorStore.view3D && !editorStore.wallConstructionMode && !editorStore.lineConstructionMode  && !editorStore.areaConstructionMode && !editorStore.rulerConstructionMode) {
            // Enable panning in the editor store when the middle mouse button is pressed
            editorStore.setPanning(true);
        }

        if (!editorStore.view3D && !editorStore.wallConstructionMode && !editorStore.lineConstructionMode && !editorStore.areaConstructionMode&& !editorStore.rulerConstructionMode && editorStore.panning) {
            isPanning.current = true;
            startPoint.current.set(event.clientX, event.clientY);
            cameraStart.current.copy(camera.position);
            document.body.style.cursor = 'grabbing';
        }
    };

    const handlePointerMove = (event: PointerEvent) => {
        if (!editorStore.view3D && !editorStore.wallConstructionMode && !editorStore.lineConstructionMode  && !editorStore.areaConstructionMode && !editorStore.rulerConstructionMode&& isPanning.current && editorStore.panning) {
            const deltaX = (event.clientX - startPoint.current.x) / camera.zoom / 44;
            const deltaY = (event.clientY - startPoint.current.y) / camera.zoom / 44;

            let newX = cameraStart.current.x - deltaX;
            let newY = cameraStart.current.y + deltaY;

            // Get the current viewport size in world units
            const { width: viewportWidth, height: viewportHeight } = viewport.getCurrentViewport(camera);

            const halfViewportWidth = viewportWidth / 2;
            const halfViewportHeight = viewportHeight / 2;

            // Maximum bounds of the plane
            const planeHalfSize = MAX_PLANE_SIZE / 2;

            // Clamp the camera position so the visible area stays within the plane
            let minX = -planeHalfSize + halfViewportWidth;
            let maxX = planeHalfSize - halfViewportWidth;

            if (viewportWidth >= MAX_PLANE_SIZE) {
                // Viewport is larger than the plane, center the camera and prevent panning
                minX = maxX = 0;
                newX = 0;
            } else {
                newX = Math.max(minX, Math.min(maxX, newX));
            }

            let minY = -planeHalfSize + halfViewportHeight;
            let maxY = planeHalfSize - halfViewportHeight;

            if (viewportHeight >= MAX_PLANE_SIZE) {
                // Viewport is larger than the plane, center the camera and prevent panning
                minY = maxY = 0;
                newY = 0;
            } else {
                newY = Math.max(minY, Math.min(maxY, newY));
            }

            camera.position.set(newX, newY, camera.position.z);

            invalidate(); // Invalidate the render to see the panning
        }
    };

    const handlePointerUp = (event: PointerEvent) => {
        // Check if the middle mouse button is released
        if (event.button === 1) {
            // Restore the previous panning state
            editorStore.setPanning(false);
        }

        if (isPanning.current) {
            isPanning.current = false;
            document.body.style.cursor = 'auto';
        }
    };
    
    return (
        <mesh
            position={[0, 0, -0.01]} // Ensure the plane is in the background
            onPointerDown={(e) => handlePointerDown(e.nativeEvent)}
            onPointerMove={(e) => handlePointerMove(e.nativeEvent)}
            onPointerUp={(e) => handlePointerUp(e.nativeEvent)}
        >
            <planeGeometry args={[MAX_PLANE_SIZE * 1.5, MAX_PLANE_SIZE * 1.5]} />
            <meshBasicMaterial
                transparent
                opacity={0}
                //color={'white'}
            />
        </mesh>
    );
};

export default PanningPlane;
