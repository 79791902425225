import React, { useEffect, useRef } from 'react';
import { useThree, useFrame, invalidate, render } from '@react-three/fiber';
import * as THREE from 'three';
import { editorStore } from '../../store/editorStore';
import { observer } from 'mobx-react-lite';
import { projectToWorld } from '../FloorPlan/projectToWorld';
import { floorplannerStore } from '../../store/floorplannerStore';
import { WallConnectionEnd, WallConnectionStart, WallType } from '../../types/wallTypes';
import { runInAction } from 'mobx';
import { updateAlignmentLines } from '../FloorPlan/updateAligmentLines';
import { renderStore } from '../../store/renderStore';

const WallConstructionPlane = observer(() => {
  const {
    wallConstructionMode,
    wallConstructionModeAddNewWall,
    view3D,
  } = editorStore;
  const { gl, camera } = useThree();
  const addNewWall = useRef(false);
  const currentWall = useRef(undefined as string | undefined);
  const lastClickPosition = useRef(undefined as THREE.Vector2 | undefined);

  const handlePointerDown = (event: PointerEvent) => {
    if (editorStore.wallConstructionMode) {
      // Get the click position of the pointer in the world
      const { clientX, clientY } = event;
      const pos = projectToWorld(clientX, clientY, gl, camera);
      lastClickPosition.current = new THREE.Vector2(pos[0], pos[1]);
      addNewWall.current = true;
      // If the current wall end is connected to another wall, exit the construction mode
      if (currentWall.current) {
        const wall = floorplannerStore.wallsMap.get(currentWall.current);
        if (wall) {
          const connection = wall.connections?.find((c) => c.sourcePosition === WallConnectionEnd);
          if (connection) {
            floorplannerStore.objectNeedsUpdate(connection.id);
            editorStore.setWallConstructionMode(false);
            floorplannerStore.recalculateRooms();
            return;
          }
        }
      }
    }
  };

  const handlePointerMove = (event: PointerEvent) => {
    if (editorStore.wallConstructionMode && !editorStore.view3D) {
      document.body.style.cursor = 'crosshair';
      const { clientX, clientY } = event;
      const pos = projectToWorld(clientX, clientY, gl, camera);

      // Get the distance between the current mouse position and the last clicked position
      const distance = lastClickPosition.current?.distanceTo(new THREE.Vector2(pos[0], pos[1])) ?? 0;

      if (addNewWall.current && distance > 0.1 && lastClickPosition.current) {
        createNewWallSegment(pos);
      }

      // Update the wall's position if it's already been created
      if (!addNewWall.current && currentWall.current) {
        //const wall = floorplannerStore.walls[currentWall.current];
        const wall = floorplannerStore.wallsMap.get(currentWall.current);
        if (!wall) {
          return;
        }
        const start = wall.start;
        let end = new THREE.Vector2(pos[0], pos[1]);
        // Check if Shift is pressed
        if (!event.shiftKey) {
          // Calculate the angle between the start and current mouse position
          const deltaX = end.x - start.x;
          const deltaY = end.y - start.y;
          const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI); // Convert to degrees

          // Get the current distance between the start and end position
          const currentDistance = start.distanceTo(end);

          // Normalize the angle to a positive value between 0 and 360
          const normalizedAngle = (angle + 360) % 360;

          // Snap the angle to the nearest 45 degrees
          const snapAngles = [0, 45, 90, 135, 180, 225, 270, 315, 360];
          let closestSnapAngle = snapAngles.reduce((prev, curr) => Math.abs(curr - normalizedAngle) < Math.abs(prev - normalizedAngle) ? curr : prev);

          // Convert the snapped angle to radians
          const radianSnapAngle = closestSnapAngle * (Math.PI / 180);

          // Calculate the snapped end position (along the nearest 45-degree line)
          const snapEndX = start.x + currentDistance * Math.cos(radianSnapAngle);
          const snapEndY = start.y + currentDistance * Math.sin(radianSnapAngle);

          // Calculate the distance between the current mouse position and the snapped position
          const snapDistance = end.distanceTo(new THREE.Vector2(snapEndX, snapEndY));

          // If the distance to the snapped angle is less than 0.2, snap to the closest 45-degree angle
          if (snapDistance < editorStore.snappingTolerance) {
            end.set(snapEndX, snapEndY);
          }
        }

        // Test to snap the wall to other walls if distance is more than 0.2
        let snapped = false;
        if (distance > editorStore.snappingTolerance) {
          runInAction(() => {
            floorplannerStore.removeConnectionEnd(wall.id, WallConnectionEnd);
            snapped = floorplannerStore.snapToOtherObjects(wall.id, WallConnectionEnd, end, false);
            if (!snapped && !editorStore.isShiftPressed) {
              floorplannerStore.updateObjectPosition(wall.id, start, end);
              const updObject = floorplannerStore.findObjectId(wall.id);
              if (updObject) {
                snapped = floorplannerStore.snapToAlignmentLines(wall.id, WallConnectionEnd, updObject.end);
                if (snapped) {
                  const updObject = floorplannerStore.findObjectId(wall.id);
                  if (updObject) {
                    updateAlignmentLines(wall.id, updObject.end.x, updObject.end.y, "end");
                  }
                }
              }
            }
          });
        }
        if (!snapped) {
          floorplannerStore.updateObjectPosition(currentWall.current, start, end);
          updateAlignmentLines(wall.id, end.x, end.y, "end");
        }
        // Update the connected wall since the connection angle might have changed
        const startConnectedToId = floorplannerStore.objectConnectedTo(wall.id, WallConnectionStart);
        if (startConnectedToId) {
          floorplannerStore.objectNeedsUpdate(startConnectedToId);
        }
        // Update the wall's outer length in property panel
        const outerLength = floorplannerStore.wallOuterLength(wall);
        // Set the length edit box with id="wallLength" in focus in the property panel
        editorStore.setWallEditingLengthPanel(outerLength);
      }
    }
  };

  const handlePointerUp = () => {
  };

  const createNewWallSegment = (pos: [number, number]) => {
    if (lastClickPosition.current && currentWall) {
      const start = currentWall.current ?
        floorplannerStore.wallsMap.get(currentWall.current)?.end.clone() as THREE.Vector2 :
        lastClickPosition.current.clone();
      const end = new THREE.Vector2(pos[0], pos[1]);
      // Create a new wall object
      const newWall: WallType = {
        id: floorplannerStore.generateId(),
        type: "wall",
        start: start as THREE.Vector2,
        end: end as THREE.Vector2,
      };
      floorplannerStore.addWall(newWall);
      //editorStore.setWallEditingOuterLength(newWall.id);  // This will make the wall width editable while constructing
      // Pause mobx observer updates for this operation
      runInAction(() => {
        if (currentWall.current) {
          const wall = floorplannerStore.wallsMap.get(currentWall.current);
          if (!wall) {
            return;
          }
          const snappedStart = floorplannerStore.snapToOtherObjects(newWall.id, WallConnectionStart, start, true);
          const snappedEnd = floorplannerStore.snapToOtherObjects(wall.id, WallConnectionEnd, end, true);
        }
      });
      // Select the new wall
      editorStore.clearSelections();
      editorStore.addSelection(newWall);
      invalidate();

      currentWall.current = newWall.id;
      addNewWall.current = false;
    }
  };

  // When the user press Enter key (like inside Edit field), editorStore.wallConstructionModeAddNewWall will signal to add a new wall
  useEffect(() => {
    if (currentWall.current && wallConstructionModeAddNewWall) {
      editorStore.setWallConstructionModeAddNewWall(false);
      const wall = floorplannerStore.wallsMap.get(currentWall.current);
      if (wall) {
        lastClickPosition.current = wall.end.clone();
        addNewWall.current = true;
      }
    }
  }, [wallConstructionModeAddNewWall]);

  useEffect(() => {
    if (currentWall.current) {
      const wall = floorplannerStore.wallsMap.get(currentWall.current);
      if (wall) {
        if (!addNewWall.current) {
          editorStore.clearSelections();
          // Get the wall previous connection
          const previousWallId = floorplannerStore.objectConnectedTo(wall.id, WallConnectionStart);
          // Remove current constructed wall since it is not click confirmed yet
          floorplannerStore.removeWall(currentWall.current);
          // If there is a previous wall, snap the previous wall to any possible connections at the end
          if (previousWallId) {
            runInAction(() => {
              const previousWall = floorplannerStore.wallsMap.get(previousWallId);
              if (previousWall) {
                const snapped = floorplannerStore.snapToOtherObjects(previousWall.id, WallConnectionEnd, previousWall.end, true);
              }
            });
          }
        } else if (addNewWall.current) {
          // Snap the current wall to any possible connections at the start
          runInAction(() => {
            if (currentWall.current) {
              const snapped = floorplannerStore.snapToOtherObjects(wall.id, WallConnectionEnd, wall.end, true);
              if (snapped) {
                // Select the wall if it is snapped to another wall
                floorplannerStore.selectWall(currentWall.current);
              }
            }
          });
        }
      }
    }
    renderStore.clearAlignmentLines();
    editorStore.setWallEditingOuterLength(undefined);
    editorStore.setWallEditingInnerLength(undefined);
    editorStore.setWallEditingLengthPanel(undefined);
    addNewWall.current = false;
    currentWall.current = undefined;
    lastClickPosition.current = undefined;
    if (wallConstructionMode) {
      document.body.style.cursor = 'crosshair';
      floorplannerStore.setBlockDirty(true);
    } else {
      document.body.style.cursor = 'auto';
      floorplannerStore.setBlockDirty(false);
    }
  }, [wallConstructionMode, view3D]);

  return (
    <mesh
      position={[0, 0, -0.01]} // Ensure the plane is in the background
      onPointerDown={(e) => handlePointerDown(e.nativeEvent)}
      onPointerMove={(e) => handlePointerMove(e.nativeEvent)}
      onPointerUp={handlePointerUp}
    >
      <planeGeometry args={[1000, 1000]} />
      <meshBasicMaterial transparent opacity={0} />
    </mesh>
  );
});

export default WallConstructionPlane;
