import { makeAutoObservable, ObservableMap, set } from 'mobx';
import React from "react";
import { useContext } from "react";
import { ApolloClient } from "@apollo/client";
import { ClippingPolygonType, isDoorType, isDoubleDoorType, isWindowType, SymbolType, WallShapeType, WallType, otherModifiedPath, AlignmentLineType } from '../types/wallTypes';
import { floorplannerStore } from "./floorplannerStore";
import * as THREE from "three";
import { composePaths } from "../components/FloorPlan/composeLines";
import { trimIntersectingLines, trimIntersectingShapes } from "../components/FloorPlan/trimIntersectingLines";

class RenderStore {
  client: ApolloClient<any> | undefined;
  wallClippingsMap = new Map<string, ClippingPolygonType[]>();
  innerWallShapesMap = new Map<string, WallShapeType[]>();
  outline1WallShapesMap = new Map<string, WallShapeType[]>();
  outline2WallShapesMap = new Map<string, WallShapeType[]>();
  endCapsWallShapesMap = new Map<string, WallShapeType[]>();
  alignmentLinesMap = new ObservableMap<string, AlignmentLineType>();
  alignmentLinesApproxMap = new ObservableMap<string, AlignmentLineType>();

  constructor() {
    makeAutoObservable(this);
  }

  dispose() {
  }

  initialize(client: ApolloClient<any>) {
    this.client = client;
  }

  getClippingPolygon = (symbol: SymbolType, wall: WallType): ClippingPolygonType => {
    const objectWidth = isDoorType(symbol)
      ? symbol.doorWidth + ((symbol.doorFrameWidth || floorplannerStore.doorFrameWidth) + floorplannerStore.convertLineWeightToWorld(wall.lineWeight || floorplannerStore.wallLineWeight)) * 2
      : isDoubleDoorType(symbol)
        ? (symbol.doubleDoorWidth + (symbol.doorFrameWidth || floorplannerStore.doorFrameWidth)) * 2
        : isWindowType(symbol)
          ? symbol.windowLength
          : 0.8;
    const objectLength = objectWidth / 2;
    const wallAngle = Math.atan2(wall.end.y - wall.start.y, wall.end.x - wall.start.x);
    const objectDirection = new THREE.Vector2(Math.cos(wallAngle), Math.sin(wallAngle));
    // If it is a door or double door, offset the position by the door/ double door width
    const offset = isDoorType(symbol) ? objectWidth / 2 - (symbol.doorFrameWidth || floorplannerStore.doorFrameWidth) - floorplannerStore.convertLineWeightToWorld(wall.lineWeight || floorplannerStore.wallLineWeight) :
      isDoubleDoorType(symbol) ? objectWidth / 2 - (symbol.doorFrameWidth || floorplannerStore.doorFrameWidth) : 0;
    const wallDirection = wall.end.clone().sub(wall.start).normalize();
    const symbolDistance = symbol.position.clone().sub(wall.start).dot(wallDirection);
    const symbolPositionOnWall = wall.start.clone().add(wallDirection.clone().multiplyScalar(symbolDistance));
    const objectPositionOffset = symbolPositionOnWall.clone().add(objectDirection.clone().multiplyScalar(offset));
    const objectLengthVector = objectDirection.clone().multiplyScalar(objectLength);
    const objectWidthVector = objectDirection.clone().rotateAround(new THREE.Vector2(0, 0), Math.PI / 2).multiplyScalar(objectWidth / 2);
    const topLeft = objectPositionOffset.clone().add(objectLengthVector).add(objectWidthVector);
    const topRight = objectPositionOffset.clone().add(objectLengthVector).sub(objectWidthVector);
    const bottomRight = objectPositionOffset.clone().sub(objectLengthVector).sub(objectWidthVector);
    const bottomLeft = objectPositionOffset.clone().sub(objectLengthVector).add(objectWidthVector);
    const clippingPolygon: ClippingPolygonType = {
      belongsTo: symbol.id,
      polygon: [
        new THREE.Vector2(topLeft.x, topLeft.y),
        new THREE.Vector2(topRight.x, topRight.y),
        new THREE.Vector2(bottomRight.x, bottomRight.y),
        new THREE.Vector2(bottomLeft.x, bottomLeft.y),
      ],
      objectType: "symbol",
    };
    return clippingPolygon;
  };

  addWallClipping = (wallId: string, clipping: ClippingPolygonType) => {
    const wallClippings = this.wallClippingsMap.get(wallId) || [];
    const index = wallClippings.findIndex(c => c.belongsTo === clipping.belongsTo);
    if (index > -1) {
      wallClippings[index] = clipping;
    } else {
      wallClippings.push(clipping);
    }
    this.wallClippingsMap.set(wallId, wallClippings);
  }

  removeWallClipping = (wallId: string, belongsTo: string) => {
    const clippings = this.wallClippingsMap.get(wallId) || [];
    const index = clippings.findIndex(c => c.belongsTo === belongsTo);
    if (index > -1) {
      clippings.splice(index, 1);
    }
    this.wallClippingsMap.set(wallId, clippings);
  }

  clearWallClippings = (wallId: string) => {
    const allClippings = this.wallClippingsMap.get(wallId);
    if (allClippings) {
      allClippings.forEach((clipping) => {
        if (clipping.objectType === "wall") {
          this.removeWallClipping(wallId, clipping.belongsTo);
        }
      });
    }
  }

  clearSymbolClippings = (wallId: string) => {
    // Clean out all previous symbol clippings
    const allClippings = this.wallClippingsMap.get(wallId);
    if (allClippings) {
      allClippings.forEach((clipping) => {
        if (clipping.objectType === "symbol" || clipping.objectType === "door" || clipping.objectType === "doubleDoor" || clipping.objectType === "window") {
          renderStore.removeWallClipping(wallId, clipping.belongsTo);
        }
      });
    }
  }

  clearClippings = (wallId?: string) => {
    if (wallId) {
      this.wallClippingsMap.set(wallId, []);
      this.wallClippingsMap.delete(wallId);
    } else {
      this.wallClippingsMap.clear();
    }
  }

  getConnectedShapes = (wallId: string, scene?: THREE.Scene): WallShapeType[] => {
    const wall = floorplannerStore.wallsMap.get(wallId);
    if (!wall) return [];
    const connectedShapes = [] as WallShapeType[];
    const connectedWalls = wall.connections?.map(c => floorplannerStore.wallsMap.get(c.id));
    connectedWalls?.forEach(w => {
      if (w) {
        let innerShapes = this.innerWallShapesMap.get(w.id);
        if (!innerShapes) {
          this.composeWallShape(w.id, scene);
          innerShapes = this.innerWallShapesMap.get(w.id);
        }
        if (innerShapes) {
          connectedShapes.push(...innerShapes);
        }
      }
    });
    return connectedShapes;
  }

  composeWallShape = (wallId: string, scene?: THREE.Scene): WallShapeType[] => {
    const innerShapes: WallShapeType[] = [];
    const outerShapes1: WallShapeType[] = [];
    const outerShapes2: WallShapeType[] = [];
    const endCaps: WallShapeType[] = [];
    composePaths(wallId, innerShapes, outerShapes1, outerShapes2, endCaps);
    this.innerWallShapesMap.set(wallId, innerShapes);
    this.outline1WallShapesMap.set(wallId, outerShapes1);
    this.outline2WallShapesMap.set(wallId, outerShapes2);
    this.endCapsWallShapesMap.set(wallId, endCaps);
    return innerShapes;
  }

  composeAllShapes = () => {
    floorplannerStore.wallsMap.forEach(wall => {
      this.composeWallShape(wall.id);
    });
  }

  shapeChanged = (shape: WallShapeType, wall: WallType): boolean => {
    if (shape.wall.start.x !== wall.start.x || shape.wall.start.y !== wall.start.y) {
      return true;
    }
    if (shape.wall.end.x !== wall.end.x || shape.wall.end.y !== wall.end.y) {
      return true;
    }
    if (shape.wall.wallWidth !== wall.wallWidth) {
      return true;
    }
    return false;
  }

  updateShapes = (wallId: string, scene?: THREE.Scene): WallShapeType[] => {
    let innerShapes = this.innerWallShapesMap.get(wallId);
    if (innerShapes) {
      const wall = floorplannerStore.wallsMap.get(wallId);
      if (!wall) return [];
      innerShapes.forEach(innerShape => {
        if (this.shapeChanged(innerShape, wall)) {
          this.composeWallShape(wallId);
          const connectingWalls = wall.connections?.map(c => floorplannerStore.wallsMap.get(c.id));
          connectingWalls?.forEach(w => {
            if (w) {
              this.trimShapes(w, scene);
            }
          });
          this.trimShapes(wall, scene);
        }
      })
    } else {
      const wall = floorplannerStore.wallsMap.get(wallId);
      if (!wall) return [];
      this.composeWallShape(wallId);
      this.trimShapes(wall, scene);
      // We have to trim the connecting walls as well, deeply nested at least 2 levels
      const connectingWalls = wall.connections?.map(c => floorplannerStore.wallsMap.get(c.id));
      connectingWalls?.forEach(w => {
        if (w) {
          this.trimShapes(w, scene);
          const connectingWalls2 = w.connections?.map(c => floorplannerStore.wallsMap.get(c.id));
          connectingWalls2?.forEach(w2 => {
            if (w2) {
              this.trimShapes(w2, scene);
            }
          });
        }
      });
    }

    innerShapes = this.innerWallShapesMap.get(wallId);
    return innerShapes ?? [];
  };

  trimShapes = (wall: WallType, scene?: THREE.Scene) => {
    const otherModifiedPaths = [] as otherModifiedPath[];
    const innerShapes = this.innerWallShapesMap.get(wall.id);
    const outline1Shapes = this.outline1WallShapesMap.get(wall.id);
    const outline2Shapes = this.outline2WallShapesMap.get(wall.id);
    if (!innerShapes || !outline1Shapes || !outline2Shapes) {
      return;
    }

    // Add the new wall clippings and update the wall shapes on their intersections
    const connectedShapes = this.getConnectedShapes(wall.id, scene);
    const trimmed1 = trimIntersectingLines(outline1Shapes, connectedShapes);
    const trimmed2 = trimIntersectingLines(outline2Shapes, connectedShapes);
    const trimmed3 = trimIntersectingShapes(innerShapes, connectedShapes, otherModifiedPaths, scene);

    //if (trimmed1 || trimmed2 || trimmed3) {

    if (innerShapes && outline1Shapes && outline2Shapes) {
      this.outline1WallShapesMap.set(wall.id, outline1Shapes);
      this.outline2WallShapesMap.set(wall.id, outline2Shapes);
      this.innerWallShapesMap.set(wall.id, innerShapes);
      otherModifiedPaths.forEach(mods => {
        //console.log("mods: ", mods, wall.id, wall.fillColor);
        const wallShapes = this.innerWallShapesMap.get(mods.wallId);
        if (wallShapes) {
          const wallShape = wallShapes.find(ws => ws.lineSide === "inner");
          if (wallShape && wallShape.paths[mods.pathIndex] !== mods.point) {
            //console.log("Modified path: ", wallShape.paths[mods.pathIndex], " to ", mods.point, wallShape.wall.fillColor);
            wallShape.paths[mods.pathIndex].x = mods.point.x;
            wallShape.paths[mods.pathIndex].y = mods.point.y;
            this.innerWallShapesMap.set(mods.wallId, wallShapes);
            // Debug draw a dot at mods.point
            // const geometry1 = new THREE.CircleGeometry(0.01, 32);
            // const material1 = new THREE.MeshBasicMaterial({ color: wallShape.wall.fillColor });
            // const circle1 = new THREE.Mesh(geometry1, material1);
            // circle1.position.set(mods.point.x, mods.point.y, 0.01);
            // scene?.add(circle1);
          }
        }
      });
    }

  }

  clearWallShapes = (wallId: string) => {
    if (wallId) {
      this.innerWallShapesMap.set(wallId, []);
      this.innerWallShapesMap.delete(wallId);
      this.outline1WallShapesMap.set(wallId, []);
      this.outline1WallShapesMap.delete(wallId);
      this.outline2WallShapesMap.set(wallId, []);
      this.outline2WallShapesMap.delete(wallId);
      this.endCapsWallShapesMap.set(wallId, []);
      this.endCapsWallShapesMap.delete(wallId);
    } else {
      this.innerWallShapesMap.clear();
      this.outline1WallShapesMap.clear();
      this.outline2WallShapesMap.clear();
      this.endCapsWallShapesMap.clear();
    }
  }

  clearAlignmentLines = () => {
    this.alignmentLinesMap.clear();
    this.alignmentLinesApproxMap.clear();
  }

  addAlignmentLine = (line: AlignmentLineType, approx: boolean) => {
    if (approx) {
      this.alignmentLinesApproxMap.set(line.id, line);
    } else {
      this.alignmentLinesMap.set(line.id, line);
    }
  }

}

export const renderStore = new RenderStore();
export const renderStoreContext = React.createContext<RenderStore>(renderStore);
export const useRenderStore = () => useContext(renderStoreContext);
