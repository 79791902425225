/* eslint-disable react/no-unknown-property */
import React, { useEffect, ReactNode, useCallback, useMemo } from "react";
import { Canvas, invalidate, useThree } from "@react-three/fiber";
import {
  CircleStairsType,
  CircleSymbolType,
  DoorType,
  DoubleDoorType,
  isAreaSymbolType,
  isCircleStairsType,
  isCircleSymbolType,
  isDoorType,
  isDoubleDoorType,
  isRectStairsType,
  isSquareSymbolType,
  isSvgType,
  isTextType,
  isTriangleSymbolType,
  isWindowType,
  RectStairsType,
  SquareSymbolType,
  SvgType,
  TextType,
  TriangleSymbolType,
  WindowType,
} from "../../types/wallTypes";
import Grid from "../FloorPlan/Grid";
import { OrbitControls } from "@react-three/drei";
import WallStructure from "../FloorPlan/WallStructure";
import { editorStoreContext } from "../../store/editorStore";
import Door from "../FloorPlan/Door";
import Window from "../FloorPlan/Window";
import { FloorplannerStoreContext, floorplannerStore } from "../../store/floorplannerStore";
import { observer } from "mobx-react-lite";
import * as THREE from "three";
import DoubleDoor from "../FloorPlan/DoubleDoor";
import CircleStairs from "../FloorPlan/CircleStairs";
import RectStairs from "../FloorPlan/RectStairs";
import SvgSymbol from "../FloorPlan/SvgSymbol";
import TextSymbol from "../FloorPlan/TextSymbol";
import CameraSetup from "../FloorPlan/CameraSetup";
import PanningPlane from "./PanningPlane";
import WallConstructionPlane from "./WallConstructionPlane";
import SelectionPlane from "./SelectionPlane";
import SquareSymbol from "../FloorPlan/SquareSymbol";
import CircleSymbol from "../FloorPlan/CircleSymbol";
import TriangleSymbol from "../FloorPlan/TriangleSymbol";
import SingleLinesStructure from "../FloorPlan/SingleLinesStructure";
import LineConstructionPlane from "./LineConstructionPlane";
import RulerLinesStructure from "../FloorPlan/RulerLinesStructure";
import RulerConstructionPlane from "./RulerConstructionPlane";
import AreaConstructionPlane from "./AreaConstructionPlane";
import AlignmentLines from "../FloorPlan/AlignmentLineStructure";
import AlignmentLineStructure from "../FloorPlan/AlignmentLineStructure";
import RoomLabelStructure from "../FloorPlan/RoomLabelStructure";
import AreaSymbol from "../FloorPlan/AreaSymbol";

interface StoreProviderProps {
  children: ReactNode;
}

export const FloorplannerStoreProvider: React.FC<StoreProviderProps> = ({
  children,
}) => {
  const { scene } = useThree();

  useEffect(() => {
    if (scene && scene.children.length > 0) {
      const box = new THREE.Box3();
      scene.children.forEach((child) => {
        if (child && child instanceof THREE.Mesh && child.geometry && (!child.geometry.boundingBox || !child.geometry.boundingBox.minX || child.geometry.boundingBox.minX.x === Infinity)) {
          return;
        }
        box.expandByObject(child);
      });
      floorplannerStore.boundingBox = box;
    }
  }, [scene.children]);

  return (
    <FloorplannerStoreContext.Provider value={floorplannerStore}>
      {children}
    </FloorplannerStoreContext.Provider>
  );
};

const FloorPlan: React.FC = observer(() => {
  const editorStore = React.useContext(editorStoreContext);
  const { saveToCloud, symbolsMap } = React.useContext(FloorplannerStoreContext);

  const handleDragStart = useCallback(() => {
    // Do nothing
  }, []);

  const handleDragEnd = useCallback((newPosition: any) => {
    // Do nothing
  }, []);

  const handleDrag = useCallback(() => {
    // Do nothing
  }, []);

  const CanvasEventHandler: React.FC = () => {
    const { camera, gl } = useThree();

    useEffect(() => {
      // Set the camera reference in editorStore
      editorStore.setCamera(camera);

      const handleWheel = (event: WheelEvent) => {
        editorStore.handleWheel(event);
      };

      const canvas = gl.domElement;
      canvas.addEventListener("wheel", handleWheel, { passive: false });

      return () => {
        canvas.removeEventListener("wheel", handleWheel);
      };
    }, [camera, editorStore, gl]);

    return null;
  };

  // Auto-save every second
  useEffect(() => {
    const interval = setInterval(() => {
      saveToCloud(false, false).catch((error) => {
        console.error("Auto-save failed:", error);
      });
    }, 1000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [saveToCloud]);

  // Detect backspace key to delete selected item
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Backspace") {
        if (document.activeElement === document.body && !editorStore.wallEditingOuterLength && !editorStore.wallEditingInnerLength) {
          editorStore.delete();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [editorStore]);

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <Canvas
        camera={{ position: [0, 0, 20], fov: 40 }}
        frameloop="demand"
        style={{ userSelect: "none" }}
        gl={{
          localClippingEnabled: true,
          antialias: true,
          toneMapping: THREE.NoToneMapping,
          //logarithmicDepthBuffer: true
        }}
      >
        <CameraSetup />
        <ambientLight intensity={1} />
        <pointLight position={[10, 10, 10]} />
        {editorStore.view3D && <OrbitControls />}
        {editorStore.showGrid && <Grid />}
        <PanningPlane />
        <SelectionPlane />
        <WallConstructionPlane />
        <LineConstructionPlane />
        <RulerConstructionPlane />
        <CanvasEventHandler />
        <AreaConstructionPlane />
        <FloorplannerStoreProvider>
          <WallStructure />
          {
            Array.from(symbolsMap.values()).map((symbol) => (
              isDoorType(symbol) ? (
                <Door key={symbol.id} door={symbol as DoorType} onDragStart={handleDragStart} onDrag={handleDrag} onDragEnd={handleDragEnd} attachedToWall={symbol.attachedTo ? floorplannerStore.wallsMap.get(symbol.attachedTo) : undefined} />
              ) : isWindowType(symbol) ? (
                <Window key={symbol.id} window={symbol as WindowType} onDragStart={handleDragStart} onDrag={handleDrag} onDragEnd={handleDragEnd} attachedToWall={symbol.attachedTo ? floorplannerStore.wallsMap.get(symbol.attachedTo) : undefined} />
              ) : isDoubleDoorType(symbol) ? (
                <DoubleDoor key={symbol.id} doubleDoor={symbol as DoubleDoorType} onDragStart={handleDragStart} onDrag={handleDrag} onDragEnd={handleDragEnd} attachedToWall={symbol.attachedTo ? floorplannerStore.wallsMap.get(symbol.attachedTo) : undefined} />
              ) : isCircleStairsType(symbol) ? (
                <CircleStairs key={symbol.id} circleStair={symbol as CircleStairsType} onDragStart={handleDragStart} onDrag={handleDrag} onDragEnd={handleDragEnd} />
              ) : isRectStairsType(symbol) ? (
                <RectStairs key={symbol.id} rectStair={symbol as RectStairsType} onDragStart={handleDragStart} onDrag={handleDrag} onDragEnd={handleDragEnd} />
              ) : isSvgType(symbol) ? (
                <SvgSymbol key={symbol.id} svg={symbol as SvgType} onDragStart={handleDragStart} onDrag={handleDrag} onDragEnd={handleDragEnd} />
              ) : isTextType(symbol) ? (
                <TextSymbol key={symbol.id} text={symbol as TextType} onDragStart={handleDragStart} onDrag={handleDrag} onDragEnd={handleDragEnd} />
              ) : isSquareSymbolType(symbol) ? (
                <SquareSymbol key={symbol.id} square={symbol as SquareSymbolType} onDragStart={handleDragStart} onDrag={handleDrag} onDragEnd={handleDragEnd} />
              ) : isCircleSymbolType(symbol) ? (
                <CircleSymbol key={symbol.id} circle={symbol as CircleSymbolType} onDragStart={handleDragStart} onDrag={handleDrag} onDragEnd={handleDragEnd} />
              ) : isTriangleSymbolType(symbol) ? (
                <TriangleSymbol key={symbol.id} triangle={symbol as TriangleSymbolType} onDragStart={handleDragStart} onDrag={handleDrag} onDragEnd={handleDragEnd} />
              ) : isAreaSymbolType(symbol) ? (
                <AreaSymbol key={symbol.id} symbol={symbol} onDragStart={handleDragStart} onDrag={handleDrag} onDragEnd={handleDragEnd} />
              ) : null
            ))
          }
          <SingleLinesStructure />
          <RulerLinesStructure />
          <AlignmentLineStructure />
          <RoomLabelStructure />
        </FloorplannerStoreProvider>
      </Canvas>
    </div>
  );
});

export default FloorPlan;
