import { useEffect, useState } from "react";
import { useThree } from "@react-three/fiber";
import {
  Room,
} from "../../types/wallTypes";
import { observer } from "mobx-react-lite";
import { throttledDetectRooms } from "./findRooms";
import { RoomLabels } from "./RoomLabels";
import { floorplannerStore } from "../../store/floorplannerStore";
import { editorStore } from "../../store/editorStore";

const RoomLabelStructure = observer(() => {
  const [rooms, setRooms] = useState<Room[]>([]);

  useEffect(() => {
    // Detect rooms after the walls are updated
    if (!editorStore.wallConstructionMode && !editorStore.areaConstructionMode && !editorStore.rulerConstructionMode && !editorStore.lineConstructionMode) {
      const detectedRooms = throttledDetectRooms();
      if (detectedRooms) {
        setRooms(detectedRooms);
      }
    }
  }, [floorplannerStore.wallsMap, floorplannerStore.singleLinesMap, floorplannerStore.lastRoomUpdate]);
  
  return (
    <>
      <RoomLabels rooms={rooms} />
    </>
  );
});

export default RoomLabelStructure;
