import { floorplannerStore } from "../../store/floorplannerStore";
import { Graph, Point, Room, WallType } from "../../types/wallTypes";

// Tolerance for floating-point comparison
const TOLERANCE = 0.1;
let lastRoomExecutionTime = 0;
let lastRooms: Room[] = [];

// Utility function to create a string key for a point (x, y)
function pointKey(point: Point): string {
    if (!point || !point.x || !point.y) return '';
    return `${point.x.toFixed(3)},${point.y.toFixed(3)}`; // Round to 3 decimal places for consistency
}

function buildGraph(): Graph {
    const graph: Graph = {};

    // Handle WallType connections using wallsMap
    floorplannerStore.wallsMap.forEach((wall: WallType) => {
        const startKey = pointKey(wall.start);
        const endKey = pointKey(wall.end);

        if (!graph[startKey]) graph[startKey] = [];
        if (!graph[endKey]) graph[endKey] = [];

        // Add connections between wall start and end
        graph[startKey].push(wall.end);
        graph[endKey].push(wall.start);

        // Handle middle wall connections
        if (wall.connections) {
            wall.connections.forEach(connection => {
                const sourceDistance = connection.sourcePosition;
                const targetDistance = connection.targetPosition;
                const targetId = connection.id;

                if (!wall.end || !wall.start) return;
                const wallAngle = Math.atan2(wall.end.y - wall.start.y, wall.end.x - wall.start.x);
                const targetWall = floorplannerStore.wallsMap.get(targetId);
                if (!targetWall || !targetWall.start || !targetWall.end) return;
                const targetWallAngle = Math.atan2(targetWall.end.y - targetWall.start.y, targetWall.end.x - targetWall.start.x);

                const sourceX = wall.start.x + sourceDistance * Math.cos(wallAngle);
                const sourceY = wall.start.y + sourceDistance * Math.sin(wallAngle);
                const targetX = targetWall.start.x + targetDistance * Math.cos(targetWallAngle);
                const targetY = targetWall.start.y + targetDistance * Math.sin(targetWallAngle);

                const sourcePosition = { x: sourceX, y: sourceY };
                const targetPosition = { x: targetX, y: targetY };

                const sourceKey = pointKey(sourcePosition);
                const targetKey = pointKey(targetPosition);

                if (!graph[sourceKey]) graph[sourceKey] = [];
                if (!graph[targetKey]) graph[targetKey] = [];

                graph[sourceKey].push(targetPosition);
                graph[targetKey].push(sourcePosition);
            });
        }
    });

    // Also for single lines
    floorplannerStore.singleLinesMap.forEach((line) => {
        const startKey = pointKey(line.start);
        const endKey = pointKey(line.end);

        if (!graph[startKey]) graph[startKey] = [];
        if (!graph[endKey]) graph[endKey] = [];

        graph[startKey].push(line.end);
        graph[endKey].push(line.start);

        // Handle middle wall connections
        if (line.connections) {
            line.connections.forEach(connection => {
                const sourceDistance = connection.sourcePosition;
                const targetDistance = connection.targetPosition;
                const targetId = connection.id;

                if (!line.end || !line.start) return;
                const lineAngle = Math.atan2(line.end.y - line.start.y, line.end.x - line.start.x);
                const targetWall = floorplannerStore.singleLinesMap.get(targetId);
                if (!targetWall || !targetWall.start || !targetWall.end) return;
                const targetWallAngle = Math.atan2(targetWall.end.y - targetWall.start.y, targetWall.end.x - targetWall.start.x);

                const sourceX = line.start.x + sourceDistance * Math.cos(lineAngle);
                const sourceY = line.start.y + sourceDistance * Math.sin(lineAngle);
                const targetX = targetWall.start.x + targetDistance * Math.cos(targetWallAngle);
                const targetY = targetWall.start.y + targetDistance * Math.sin(targetWallAngle);

                const sourcePosition = { x: sourceX, y: sourceY };
                const targetPosition = { x: targetX, y: targetY };

                const sourceKey = pointKey(sourcePosition);
                const targetKey = pointKey(targetPosition);

                if (!graph[sourceKey]) graph[sourceKey] = [];
                if (!graph[targetKey]) graph[targetKey] = [];

                graph[sourceKey].push(targetPosition);
                graph[targetKey].push(sourcePosition);
            });
        }
    });

    return graph;
}


// Function to detect rooms (cycles) using DFS (Depth First Search)
function findRooms(graph: Graph): Room[] {
    const rooms: Room[] = [];
    const visitedEdges = new Set<string>();

    function dfs(node: string, start: string, path: Point[], edgeStack: string[]): void {
        const [x, y] = node.split(',').map(Number);
        path.push({ x, y });

        const neighbors = graph[node];
        for (let neighbor of neighbors) {
            const neighborKey = pointKey(neighbor);
            const edgeKey = `${node}-${neighborKey}`;
            const reverseEdgeKey = `${neighborKey}-${node}`;

            if (!visitedEdges.has(edgeKey)) {
                edgeStack.push(edgeKey);
                visitedEdges.add(edgeKey);
                visitedEdges.add(reverseEdgeKey);

                if (neighborKey === start && path.length > 2) {
                    rooms.push([...path]);
                } else {
                    dfs(neighborKey, start, path, edgeStack);
                }

                visitedEdges.delete(edgeKey);
                visitedEdges.delete(reverseEdgeKey);
                edgeStack.pop();
            }
        }

        path.pop();
    }

    // Iterate over each node and run DFS
    for (let node in graph) {
        for (let neighbor of graph[node]) {
            const path: Point[] = [];
            const edgeStack: string[] = [];
            dfs(node, node, path, edgeStack);
        }
    }

    return rooms;
}

// Main function to detect rooms from walls
export function detectRooms(): Room[] {
    const graph = buildGraph();
    const rooms = findRooms(graph);
    return rooms;
}

export function throttledDetectRooms(): Room[] | null {
    const currentTime = performance.now();

    // Check if 1 second (1000 ms) has passed since the last call
    if (currentTime - lastRoomExecutionTime > 1000) {
        lastRoomExecutionTime = currentTime;
        lastRooms = detectRooms();
    }
    return lastRooms;
}

// Utility function to calculate the area of a polygon (room) using the Shoelace theorem
export function calculatePolygonArea(points: Point[]): number {
    let area = 0;
    const n = points.length;
    for (let i = 0; i < n; i++) {
        const j = (i + 1) % n;
        area += points[i].x * points[j].y - points[j].x * points[i].y;
    }
    return Math.abs(area / 2);
}

// Utility function to calculate the centroid of a polygon (room)
export function calculatePolygonCentroid(points: Point[]): Point {
    let centroid = { x: 0, y: 0 };
    let signedArea = 0;

    for (let i = 0; i < points.length; i++) {
        const x0 = points[i].x;
        const y0 = points[i].y;
        const x1 = points[(i + 1) % points.length].x;
        const y1 = points[(i + 1) % points.length].y;
        const a = x0 * y1 - x1 * y0;
        signedArea += a;
        centroid.x += (x0 + x1) * a;
        centroid.y += (y0 + y1) * a;
    }

    signedArea *= 0.5;
    centroid.x /= 6 * signedArea;
    centroid.y /= 6 * signedArea;

    return centroid;
}
