import React, { useEffect, useState, useCallback, useRef } from "react";
import { useFrame, useThree, invalidate, ThreeEvent } from "@react-three/fiber";
import * as THREE from "three";
import { observer } from "mobx-react-lite";
import { useFloorplannerStore } from '../../store/floorplannerStore';
import { throttledDetectRooms } from "./findRooms";
import SingleLine from "./SingleLine"; // Import the new SingleLine component
import { Room, SingleLineType, WallConnectionEnd, WallConnectionStart } from "../../types/wallTypes";
import { editorStore } from "../../store/editorStore";
import { RoomLabels } from "./RoomLabels";
import { updateDragHandles } from "./updateDragHandles";
import { projectToWorld } from "./projectToWorld";
import { action } from "mobx";
import { dragControlHandle } from "./dragControlHandle";
import { updateAlignmentLines } from "./updateAligmentLines";
import { dragWallHandle } from "./dragWallHandle";
import { handleSize, handleWidth } from "./createMiddleDragHandle";
import { dragStartHandle } from "./dragStartHandle";
import { dragEndHandle } from "./dragEndHandle";
import { updateWallConnections } from "./updateWallConnections";
import { dragMiddleVHandle } from "./dragMiddleVHandle";
import { dragMiddleHHandle } from "./dragMiddleHHandle";
import { MiddleVHandle } from "./MiddleVHandle";
import { MiddleHHandle } from "./MiddleHHandle";

const SingleLinesStructure = observer(() => {
  const floorplannerStore = useFloorplannerStore();
  const [rooms, setRooms] = useState<Room[]>([]); // Explicitly set the type to Room[]
  const { camera, gl } = useThree();
  const dragLine = useRef<SingleLineType | undefined>();
  const dragOffset = useRef<[number, number, number, number] | null>(null);
  const dragHandle = useRef<{ handle: string; startPosition: THREE.Vector3; endPosition: THREE.Vector3 }>({
    handle: "",
    startPosition: new THREE.Vector3(),
    endPosition: new THREE.Vector3(),
  });
  const [prevCameraZoom, setPrevCameraZoom] = useState(camera.zoom);
  const [hoveredLine, setHoveredLine] = useState<string | null>(null);
  const [hoveredHandle, setHoveredHandle] = useState<string | null>(null);
  const isHovered = (handle: string) => hoveredHandle === handle;
  const [dragEndFreeToConnect, setDragEndFreeToConnect] = useState(false);
  const noopRaycast = () => null;
  const wallMinMidHandleSize = editorStore.zoomLevel < 3 ? Math.max(0.15, 0.15 / editorStore.zoomLevelDivisor()) : 0.1; // Minimum size of the mid-handle
  const wallMidHandleGap = 0.09 / editorStore.zoomLevelDivisor(); // Gap between the mid-handles

  useFrame(() => {
    if (camera.zoom !== prevCameraZoom) {
      setPrevCameraZoom(camera.zoom);
      invalidate();
    }
  });

  useEffect(() => {
    const detectedRooms = throttledDetectRooms();
    if (detectedRooms) {
      setRooms(detectedRooms);
    }
  }, [floorplannerStore.wallsMap, floorplannerStore.lastRoomUpdate]);

  const handlePointerMove = useCallback(
    action((e: PointerEvent) => {
      if (dragLine.current !== undefined && dragOffset.current) {
        if (!editorStore.lineDragging) editorStore.setLineDragging(dragLine.current.id);
        let [newX, newY] = projectToWorld(e.clientX, e.clientY, gl, camera);
        const [startOffsetX, startOffsetY, endOffsetX, endOffsetY] =
          dragOffset.current;
        const line = dragLine.current;
        if (!line) return;

        //Update alignment lines based on the handle type
        updateAlignmentLines(
          line.id,
          newX,
          newY,
          dragHandle.current.handle,
        );

        let delta = new THREE.Vector2(0, 0);
        let snapped = false;
        if (dragHandle.current.handle === "start") {
          snapped = dragStartHandle(line, startOffsetX, startOffsetY, newX, newY, dragEndFreeToConnect, delta);
        } else if (dragHandle.current.handle === "end") {
          snapped = dragEndHandle(line, endOffsetX, endOffsetY, newX, newY, dragEndFreeToConnect, delta);
        } else if (dragHandle.current.handle === "middleV") {
          snapped = dragMiddleVHandle(line, startOffsetX, startOffsetY, newX, newY, delta);
        } else if (dragHandle.current.handle === "middleH") {
          snapped = dragMiddleHHandle(line, startOffsetX, startOffsetY, newX, newY, delta);
        } else if (dragHandle.current.handle === "wall") {
          snapped = dragWallHandle(line, startOffsetX, startOffsetY, endOffsetX, endOffsetY, newX, newY, delta);
        } else if (dragHandle.current.handle === "control") {
          dragControlHandle(line, newX, newY);
        }
        updateDragHandles(dragHandle.current?.handle || "", line, dragHandle);

        if (!delta.equals(new THREE.Vector2(0, 0))) {
          updateWallConnections(
            line.id,
            dragHandle.current?.handle || null,
            [line.id],
            floorplannerStore
          );
        }
      }
    }),
    [editorStore.lineDragging, projectToWorld, updateDragHandles, camera.zoom],
  );

  const handlePointerUp = useCallback(() => {
    dragLine.current = undefined;
    dragOffset.current = null;
    dragHandle.current.handle = "";
    editorStore.setLineDragging(null);
    // Change cursor back to auto when dragging is done
    document.body.style.cursor = "auto";
    gl.domElement.removeEventListener("pointermove", handlePointerMove);
    gl.domElement.removeEventListener("pointerup", handlePointerUp);
    floorplannerStore.setBlockDirty(false);
    floorplannerStore.recalculateRooms();
    editorStore.setWhichEndToUpdate(undefined);
  }, [gl.domElement, handlePointerMove]);


  // Define the callback functions with appropriate types
  const onLinePointerDown = useCallback((event: ThreeEvent<PointerEvent>, id: string) => {
    if (editorStore.lineDragging  || editorStore.wallConstructionMode || editorStore.lineConstructionMode || editorStore.rulerConstructionMode || editorStore.areaConstructionMode) return;
    event.stopPropagation();
    // Change cursor to grabbing when dragging a wall
    document.body.style.cursor = "grabbing";
    const line = floorplannerStore.singleLinesMap.get(id);
    if (!line) return;
    dragLine.current = line;
    updateDragHandles(dragHandle.current?.handle || "wall", line, dragHandle);
    const [worldX, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);
    const startOffsetX = worldX - line.start.x;
    const startOffsetY = worldY - line.start.y;
    const endOffsetX = worldX - line.end.x;
    const endOffsetY = worldY - line.end.y;
    dragOffset.current = [startOffsetX, startOffsetY, endOffsetX, endOffsetY];
    editorStore.clearSelections();
    editorStore.addSelection(line);
    floorplannerStore.selectLine(line.id);

    gl.domElement.addEventListener("pointermove", handlePointerMove);
    gl.domElement.addEventListener("pointerup", handlePointerUp);
    floorplannerStore.setBlockDirty(true);

  }, []);

  const onLinePointerEnter = useCallback((event: ThreeEvent<PointerEvent>, id: string) => {
    if (editorStore.lineDragging || editorStore.wallConstructionMode || editorStore.lineConstructionMode || editorStore.rulerConstructionMode || editorStore.areaConstructionMode) return;
    event.stopPropagation();
    // Change cursor to grab when hovering over the line
    if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.add("cursor-vector");
    setHoveredLine(id);
  }, []);

  const onLinePointerLeave = useCallback((event: ThreeEvent<PointerEvent>, id: string) => {
    if (editorStore.lineDragging || editorStore.wallConstructionMode || editorStore.lineConstructionMode || editorStore.rulerConstructionMode || editorStore.areaConstructionMode) return;
    // Reset cursor when leaving a wall
    if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.remove("cursor-vector");;
    setHoveredLine(null);
  }, []);

  const handleKeyPress = useCallback((event: React.KeyboardEvent<HTMLInputElement>, line: SingleLineType) => {
    // Implementation for key press handling
    if (event.key === "Enter") {
      console.log("Key Pressed Enter", line);
    }
  }, []);

  const handleLengthClick = useCallback((lineId: string) => {
    // Implementation for handling length click
    console.log("Length Click", lineId);
  }, []);

  const onLineSelectionPointerDown = useCallback(
    (
      event: ThreeEvent<PointerEvent>,
      line: SingleLineType,
      handle: "start" | "end" | "middleV" | "middleH" | "control",
    ) => {
      event.stopPropagation();
      dragLine.current = line;
      updateDragHandles(handle, line, dragHandle);
      const [worldX, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);
      let offsetX, offsetY;

      if (handle === "middleV" || handle === "middleH" || handle === "control") {
        const midpointX = (line.start.x + line.end.x) / 2;
        const midpointY = (line.start.y + line.end.y) / 2;
        offsetX = worldX - midpointX;
        offsetY = worldY - midpointY;
      } else {
        offsetX = worldX - (handle === "start" ? line.start.x : line.end.x);
        offsetY = worldY - (handle === "start" ? line.start.y : line.end.y);
      }
      if (handle === "start" || handle === "end") {
        const isWallEdgeConnected = line.connections?.find(
          (c) => (c.targetPosition === WallConnectionStart || c.targetPosition === WallConnectionEnd) &&
            c.sourcePosition === (handle === 'start' ? WallConnectionStart : WallConnectionEnd),
        );
        setDragEndFreeToConnect(!isWallEdgeConnected);
      }

      dragOffset.current = [offsetX, offsetY, offsetX, offsetY];
      editorStore.clearSelections();
      editorStore.addSelection(line);
      floorplannerStore.selectLine(line.id);
      gl.domElement.addEventListener("pointermove", handlePointerMove);
      gl.domElement.addEventListener("pointerup", handlePointerUp);
      floorplannerStore.setBlockDirty(true);
    },
    [
      floorplannerStore.singleLinesMap,
      updateDragHandles,
      projectToWorld,
      gl.domElement,
      handlePointerMove,
      handlePointerUp,
      camera.zoom
    ],
  );

  return (
    <group>
      {floorplannerStore.singleLinesMap.size > 0 &&
        Array.from(floorplannerStore.singleLinesMap.values()).map((line) => (
          <group
            key={`group-line-${line.id}`}
          >
            <SingleLine
              key={`line-${line.id}`}
              line={line}
              color={(hoveredLine === line.id || line.selected) ?
                line.hoverColor?.toString() ?? "blue" :
                line.lineColor ?
                  line.lineColor.toString() :
                  "black"}
              onLinePointerDown={onLinePointerDown}
              onLinePointerEnter={onLinePointerEnter}
              onLinePointerLeave={onLinePointerLeave}
              handleKeyPress={handleKeyPress}
              handleLengthClick={handleLengthClick}
            />
            {(line.selected && !editorStore.wallConstructionMode && !editorStore.lineConstructionMode && !editorStore.rulerConstructionMode && !editorStore.areaConstructionMode) && (
              <group>
                {(editorStore.lineDragging !== line.id || dragHandle.current?.handle === "start") && (
                  <group
                    position={new THREE.Vector3(line.start.x, line.start.y, 0.001)}
                    onPointerDown={(event) => onLineSelectionPointerDown(event, line, "start")}
                    onPointerEnter={(event) => {
                      event.stopPropagation();
                      document.body.style.cursor = "move";
                      setHoveredHandle("start")
                    }}
                    onPointerLeave={(event) => {
                      event.stopPropagation();
                      if (editorStore.lineDragging !== line.id) document.body.style.cursor = "auto";
                      setHoveredHandle(null)
                    }}
                  >
                    <mesh>
                      <planeGeometry args={[(handleWidth * 2), (handleWidth * 2)]} />
                      <meshBasicMaterial
                        //color="white"
                        transparent
                        opacity={0.0}
                      />
                    </mesh>
                    <mesh raycast={noopRaycast}>
                      <torusGeometry args={[handleSize / editorStore.zoomLevelDivisor() / 1.8, 0.003, 16, 100]} />
                      <meshBasicMaterial color="blue" />
                    </mesh>
                    <mesh
                      position={[0, 0, 0]}
                      rotation={[Math.PI / 2, 0, 0]}
                      raycast={noopRaycast}
                    >
                      <cylinderGeometry args={[handleSize / editorStore.zoomLevelDivisor() / 1.8, 0, 0, 32]} />
                      <meshBasicMaterial color={isHovered("start") ? "blue" : "white"} />
                    </mesh>
                  </group>
                )}
                {(editorStore.lineDragging !== line.id || dragHandle.current?.handle === "end") && (
                  <group
                    position={new THREE.Vector3(line.end.x, line.end.y, 0.001)}
                    onPointerDown={(event) => onLineSelectionPointerDown(event, line, "end")}
                    onPointerEnter={(event) => {
                      event.stopPropagation();
                      document.body.style.cursor = "move";
                      setHoveredHandle("end")
                    }}
                    onPointerLeave={(event) => {
                      event.stopPropagation();
                      if (editorStore.lineDragging !== line.id) document.body.style.cursor = "auto";
                      setHoveredHandle(null)
                    }}
                  >
                    <mesh>
                      <planeGeometry args={[(handleWidth * 2), (handleWidth * 2)]} />
                      <meshBasicMaterial
                        //color="white"
                        transparent
                        opacity={0.0}
                      />
                    </mesh>
                    <mesh raycast={noopRaycast}>
                      <torusGeometry args={[handleSize / editorStore.zoomLevelDivisor() / 1.8, 0.003, 16, 100]} />
                      <meshBasicMaterial color="blue" />
                    </mesh>
                    <mesh
                      position={[0, 0, 0]}
                      rotation={[Math.PI / 2, 0, 0]}
                      raycast={noopRaycast}
                    >
                      <cylinderGeometry args={[handleSize / editorStore.zoomLevelDivisor() / 1.8, 0, 0, 32]} />
                      <meshBasicMaterial color={isHovered("end") ? "blue" : "white"} />
                    </mesh>
                  </group>
                )}
                {/* Middle handles */}
                {(editorStore.lineDragging !== line.id || dragHandle.current?.handle === "middleV") && (
                  <MiddleVHandle
                    wall={line}
                    wallWidth={Math.max(wallMinMidHandleSize, 0.1)}
                    onHandlePointerDown={(event: ThreeEvent<PointerEvent>) => onLineSelectionPointerDown(event, line, "middleV")}
                    onHandlePointerEnter={(event: ThreeEvent<PointerEvent>) => {
                      event.stopPropagation();
                      document.body.classList.add("cursor-vector")
                      setHoveredHandle("middleV")
                    }}
                    onHandlePointerLeave={(event: ThreeEvent<PointerEvent>) => {
                      event.stopPropagation();
                      if (editorStore.lineDragging !== line.id)  document.body.classList.remove("cursor-vector")
                      setHoveredHandle(null)
                    }}
                    fillColor={isHovered("middleV") || dragHandle.current?.handle === "middleV" ? "blue" : "white"}
                    color={isHovered("middleV") || dragHandle.current?.handle === "middleV" ? "white" : "black"}
                  />
                )}
                {(editorStore.lineDragging !== line.id || dragHandle.current?.handle === "middleH") && (
                  <MiddleHHandle
                    wall={line}
                    wallWidth={Math.max(wallMinMidHandleSize, 0.1)}
                    onHandlePointerDown={(event: ThreeEvent<PointerEvent>) => onLineSelectionPointerDown(event, line, "middleH")}
                    onHandlePointerEnter={(event: ThreeEvent<PointerEvent>) => {
                      event.stopPropagation();
                      document.body.classList.add("cursor-vector")
                      setHoveredHandle("middleH")
                    }}
                    onHandlePointerLeave={(event: ThreeEvent<PointerEvent>) => {
                      event.stopPropagation();
                      if (editorStore.lineDragging !== line.id)  document.body.classList.remove("cursor-vector")
                      setHoveredHandle(null)
                    }}
                    fillColor={isHovered("middleH") || dragHandle.current?.handle === "middleH" ? "blue" : "white"}
                    color={isHovered("middleH") || dragHandle.current?.handle === "middleH" ? "white" : "black"}
                  />
                )}

              </group>
            )}
          </group>
        ))
      }
    </group>
  );
});

export default SingleLinesStructure;
