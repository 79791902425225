import { useRef, useEffect, useState, useCallback } from "react";
import { invalidate, ThreeEvent, useFrame, useThree } from "@react-three/fiber";
import { useFloorplannerStore, floorplannerStore } from '../../store/floorplannerStore';
import {
  Room,
} from "../../types/wallTypes";
import { observer } from "mobx-react-lite";
import { throttledDetectRooms } from "./findRooms";
import { WallShape } from "./WallShape";

const WallStructure = observer(() => {
  const floorplannerStore = useFloorplannerStore();
  const {
    wallsMap,
  } = floorplannerStore;
  const { gl } = useThree();

  useEffect(() => {
    const handleContextLost = (event: Event) => {
      event.preventDefault();
      console.warn("WebGL context lost");
    };

    const handleContextRestored = () => {
      console.log("WebGL context restored");
      invalidate();
    };

    gl.domElement.addEventListener(
      "webglcontextlost",
      handleContextLost as EventListener,
    );
    gl.domElement.addEventListener(
      "webglcontextrestored",
      handleContextRestored as EventListener,
    );

    return () => {
      gl.domElement.removeEventListener(
        "webglcontextlost",
        handleContextLost as EventListener,
      );
      gl.domElement.removeEventListener(
        "webglcontextrestored",
        handleContextRestored as EventListener,
      );
    };
  }, [gl.domElement]);

  return (
    <>
      {/* Render walls */}
      {Array.from(wallsMap.values()).map((wall) => (
        <WallShape
          key={wall.id}
          wall={wall}
        />
      ))}
    </>
  );
});

export default WallStructure;