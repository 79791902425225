import React, { useEffect } from 'react';
import { floorplannerStore } from '../../store/floorplannerStore';

import SpotlightIcon from '../../assets/symbols/belysning2_alt.svg';
import LightFixIcon from '../../assets/symbols/belysning2.svg';
import DoubleDoorIcon from '../../assets/symbols/Dubbeldörr.svg';

import Sofa2seatIcon from '../../../src/assets/symbols/2-sist soffa-01.svg';
import Sofa3seatIcon from '../../../src/assets/symbols/3 sists soffa-01.svg';
import ModularSofaIcon from '../../../src/assets/symbols/modulsoffa-01.svg';
import ChaiseSofaIcon from '../../../src/assets/symbols/schäslong-01.svg';
import LoungeChair1Icon from '../../../src/assets/symbols/fåtölj  2-01.svg';
import LoungeChair2Icon from '../../../src/assets/symbols/fåtölj 3-01.svg';
import ArmChairIcon from '../../../src/assets/symbols/Fåtölj standard-01.svg';
import OctagonalCoffTabIcon from '../../../src/assets/symbols/poly 1-01.svg';
import PentagonalCoffTabIcon from '../../../src/assets/symbols/poly 2-01.svg'
import HexagonalCoffTabIcon from '../../../src/assets/symbols/poly 3-01.svg'
import OrganicCoffTabIcon from '../../../src/assets/symbols/soffbord 2-01.svg'
import OvalCoffTabIcon from '../../../src/assets/symbols/matbord-01.svg'
import Chair6TableIcon from '../../../src/assets/symbols/6 bord ny-01.svg'
import Chair4TableIcon from '../../../src/assets/symbols/4-bord ny-01.svg'
import Chair2TableIcon from '../../../src/assets/symbols/2-bord ny-01.svg'
import RoundTableIcon from '../../../src/assets/symbols/runt bord ny-01.svg'
import ArmChairDRIcon from '../../../src/assets/symbols/stol 2-01.svg'
import ChairIcon from '../../../src/assets/symbols/stol ny-01.svg'
import DishWasherIcon from '../../../src/assets/symbols/dishwasher.svg'
import BasinIcon from '../../../src/assets/symbols/handfat ny-01.svg'
import BathtubIcon from '../../../src/assets/symbols/badkar 1 ny-01.svg'
import ToiletIcon from '../../../src/assets/symbols/toa ny-01.svg'
import ShowerIcon from '../../../src/assets/symbols/dusch.svg'
import KingBedIcon from '../../../src/assets/symbols/dubbelsäng ny-01.svg'
import SingleBedIcon from '../../../src/assets/symbols/enkelsäng ny-01.svg'
import NoSVGIcon from '../../../src/assets/symbols/TransparentImage.png'
import PlantIcon from '../../../src/assets/symbols/växt.svg'
import TriangleIcon from '../../../src/assets/symbols/triangle.svg'
import StairsIcon from '../../../src/assets/symbols/Trappa.svg'
import SquareIcon from '../../../src/assets/symbols/square.svg'
import CircleIcon from '../../../src/assets/symbols/circle.svg'
import ArrowIcon from '../../../src/assets/symbols/rightarrow.svg'
import DoubbleArrowIcon from '../../../src/assets/symbols/pil.svg'
import LineIcon from '../../../src/assets/symbols/line.svg'
import Curtain1Icon from '../../../src/assets/symbols/Gardin.svg'
import Curtain2Icon from '../../../src/assets/symbols/Gardin 2.svg'
import Hanger1Icon from '../../../src/assets/symbols/galgar 1 ny-01.svg'
import Hanger2Icon from '../../../src/assets/symbols/galgar 3 ny-01.svg'
import Hanger3Icon from '../../../src/assets/symbols/galgar 4 ny-01.svg'
import Hanger4Icon from '../../../src/assets/symbols/galgar 2 ny-01.svg'
import MeasuringStickIcon from '../../../src/assets/symbols/distanceline.svg'
import SingleDoorIcon from '../../../src/assets/symbols/Dörr 1.svg'
import WindowIcon from '../../../src/assets/symbols/fönster1.svg'
import { RulerLineType, SingleLineType, WallType } from '../../types/wallTypes';
import { Vector2 } from 'three';
import { editorStore } from '../../store/editorStore';
import { PiLineSegmentsLight } from "react-icons/pi";
import { IoClose } from 'react-icons/io5';
import { observer } from 'mobx-react-lite';

interface DropdownProps {
  position: { top: number; left: number };
  onClose: () => void;
  dropdownRef: React.RefObject<HTMLDivElement>;
}

export const SymbolsPanel: React.FC<DropdownProps> = observer(({ position, onClose, dropdownRef }) => {

  // Prevent clicks inside the panel from closing it
  const handlePanelClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();  // Prevents the event from bubbling up
  };

  const handleIconClick = (label: string, props?: any) => {
    // Retrieve the visible center from the editor store
    const [centerX, centerY] = editorStore.getVisibleCenter();
    if (label === "Wall") {
      // Set a default wall length, for example 5 units (adjust based on your needs)
      const wallLength = 5;

      // Calculate the start and end points of the wall, centered around the visible center
      const startX = centerX - wallLength / 2;
      const endX = centerX + wallLength / 2;
      const startY = centerY;
      const endY = centerY;

      const wall: WallType = {
        id: floorplannerStore.generateId(),
        type: "wall",
        start: new Vector2(startX, startY), // Start point to the left of the center
        end: new Vector2(endX, endY), // End point to the right of the center

      }
      floorplannerStore.addWall(wall);
    } else if (label === "SingleLine") {
      // Set a default line length, for example 5 units (adjust based on your needs)
      const singleLineLength = 5;
      // Calculate the start and end points of the wall, centered around the visible center
      const startX = centerX - singleLineLength / 2;
      const endX = centerX + singleLineLength / 2;
      const startY = centerY;
      const endY = centerY;

      const line: SingleLineType = {
        id: floorplannerStore.generateId(),
        type: "singleLine",
        start: new Vector2(startX, startY), // Start point to the left of the center
        end: new Vector2(endX, endY), // End point to the right of the center
      }
      floorplannerStore.addSingleLine(line);
    } else if (label === "RulerLine") {
      // Set a default line length, for example 5 units (adjust based on your needs)
      const rulerLineLength = 2;
      // Calculate the start and end points of the wall, centered around the visible center
      const startX = centerX - rulerLineLength / 2;
      const endX = centerX + rulerLineLength / 2;
      const startY = centerY;
      const endY = centerY;

      const line: RulerLineType = {
        id: floorplannerStore.generateId(),
        type: "rulerLine",
        start: new Vector2(startX, startY), // Start point to the left of the center
        end: new Vector2(endX, endY), // End point to the right of the center
      }
      floorplannerStore.addRulerLine(line);
    } else if (label === "Door") {
      floorplannerStore.addSymbol("door", [0, 0]);
    } else if (label === "WallConstructionMode") {
      editorStore.setWallConstructionMode(!editorStore.wallConstructionMode);
      onClose();
    } else if (label === "LineConstructionMode") {
      editorStore.setLineConstructionMode(!editorStore.lineConstructionMode);
      onClose();
    } else if (label === "RulerConstructionMode") {
      editorStore.setRulerConstructionMode(!editorStore.rulerConstructionMode);
      onClose();
    }  else if (label === "AreaConstructionMode") {
      editorStore.setAreaConstructionMode(!editorStore.areaConstructionMode);
      onClose();
    } else if (label === "DoubleDoor") {
      floorplannerStore.addSymbol("doubleDoor", [0, 0]);
    } else if (label === "CircleStairs") {
      floorplannerStore.addSymbol("circleStairs", [0, 0]);
    } else if (label === "RectStairs") {
      floorplannerStore.addSymbol("rectStairs", [0, 0]);
    } else if (label === "SquareSymbol") {
      floorplannerStore.addSymbol("square", [0, 0]);
    } else if (label === "CircleSymbol") {
      floorplannerStore.addSymbol("circle", [0, 0]);
    } else if (label === "TriangleSymbol") {
      floorplannerStore.addSymbol("triangle", [0, 0]);
    } else if (label === "SvgSymbol") {
      floorplannerStore.addSymbol("svg", [0, 0], props);
    } else if (label === "TextSymbol") {
      floorplannerStore.addSymbol("text", [0, 0]);
    } else if (label === "Window") {
      floorplannerStore.addSymbol("window", [0, 0]);
    } else if (label === "AreaSymbol") {
      floorplannerStore.addSymbol("area", [0, 0], {
        vertices: [
          new Vector2(0, 0),
          new Vector2(1, 0),
          new Vector2(1.5, 0.5),
          new Vector2(1, 1),
          new Vector2(0, 1),
        ],
      });
    }
  };

  return (
    <div
      className="fixed z-50 w-80 bg-white border border-gray-300 rounded shadow-lg p-4 mb-8 overflow-y-auto scrollbar"
      style={{ top: position.top, left: position.left, maxHeight: '70vh' }}
      ref={dropdownRef}
      onClick={handlePanelClick}
    >
      <div className="flex flex-cols items-center justify-between p-2">
        <div className="text-sm font-normal">Symbol library</div>
        <div onClick={onClose} className="text-sm text-gray-500"><IoClose style={{ strokeWidth: 0.5 }} size={24} /></div>
      </div>
      {/* Divider line */}
      <div className="border-b border-gray-300 w-full" />
      {/* Group 1 */}
      <div className="mb-4">
        <div className=" border-gray-300 pb-2 mb-2 mt-4 text-sm font-semibold">Walls</div>
        <div className="grid grid-cols-3 gap-4">
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("Wall")}
          >
            <div className="w-12 h-12 bg-gray-300 rounded"></div>
            <div className="text-xs mt-1">Single wall</div>
          </div>
         
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SingleLine")}
          >
            <div className="w-12 h-12 bg-gray-300 rounded"></div>
            <div className="text-xs mt-1">Single line</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("RulerLine")}
          >
            <div className="w-12 h-12 bg-gray-300 rounded"></div>
            <div className="text-xs mt-1">Ruler line</div>
          </div>
          {/* <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("AreaSymbol")}
          >
            <div className="w-12 h-12 bg-gray-300 rounded"></div>
            <div className="text-xs mt-1">Area Component</div>
          </div> */}
          <div
            className={`flex flex-col items-center cursor-pointer`}
            onClick={() => handleIconClick("WallConstructionMode")}
          >
            <div className={`w-12 h-12 rounded flex justify-center items-center ${editorStore.wallConstructionMode ? "bg-gray-400" : "bg-gray-300"}`}>
              <PiLineSegmentsLight className="w-full h-full object-contain p-2" />
            </div>
            <div className="text-xs mt-1 text-center">Wall construction mode</div>
          </div>
          <div
            className={`flex flex-col items-center cursor-pointer`}
            onClick={() => handleIconClick("LineConstructionMode")}
          >
            <div className={`w-12 h-12 rounded flex justify-center items-center ${editorStore.lineConstructionMode ? "bg-gray-400" : "bg-gray-300"}`}>
              <PiLineSegmentsLight className="w-full h-full object-contain p-2" />
            </div>
            <div className="text-xs mt-1 text-center">Line construction mode</div>
          </div>
          <div
            className={`flex flex-col items-center cursor-pointer`}
            onClick={() => handleIconClick("AreaConstructionMode")}
          >
            <div className={`w-12 h-12 rounded flex justify-center items-center ${editorStore.areaConstructionMode ? "bg-gray-400" : "bg-gray-300"}`}>
              <PiLineSegmentsLight className="w-full h-full object-contain p-2" />
            </div>
            <div className="text-xs mt-1 text-center">Area construction mode</div>
          </div>
          <div
            className={`flex flex-col items-center cursor-pointer`}
            onClick={() => handleIconClick("RulerConstructionMode")}
          >
            <div className={`w-12 h-12 rounded flex justify-center items-center ${editorStore.rulerConstructionMode ? "bg-gray-400" : "bg-gray-300"}`}>
              <PiLineSegmentsLight className="w-full h-full object-contain p-2" />
            </div>
            <div className="text-xs mt-1 text-center">Ruler construction mode</div>
          </div>
        </div>
      </div>

      {/* Group 2 */}
      <div className="mb-4">
        <div className="border-b border-gray-300 pb-2 mb-2 text-sm font-semibold">Doors & Windows</div>
        <div className="grid grid-cols-3 gap-4">
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("Door")}
          >
            <div className="w-12 h-12 bg-gray-300 rounded flex justify-center items-center">
              <img src={SingleDoorIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Single door</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("DoubleDoor")}
          >
            <div className="w-12 h-12 bg-gray-300 rounded flex justify-center items-center">
              <img src={DoubleDoorIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Double door</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("Window")}
          >
            <div className="w-12 h-12 bg-gray-300 rounded flex justify-center items-center">
              <img src={WindowIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Window</div>
          </div>
        </div>
      </div>

      {/* Group 3 */}
      <div className="mb-4">
        <div className="border-b border-gray-300 pb-2 mb-2 text-sm font-semibold">Stairs</div>
        <div className="grid grid-cols-3 gap-4">
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("CircleStairs")}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={NoSVGIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Circle stairs</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("RectStairs")}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={StairsIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Rectangle stairs </div>
          </div>
        </div>
      </div>
      {/* Group 3 */}
      <div className="mb-4">
        <div className="border-b border-gray-300 pb-2 mb-2 text-sm font-semibold">Scale rulers & Measuring sticks</div>
        <div className="grid grid-cols-3 gap-4">
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: "", svgLength: 2, svgHeight: 1.2, name: "Measuring stick" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={NoSVGIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Measuring stick</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: "", svgLength: 2, svgHeight: 1.2, name: "Measuring arrow" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={NoSVGIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Measuring arrow</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: "", svgLength: 2, svgHeight: 1, name: "Scale ruler" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={NoSVGIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Scale ruler</div>
          </div>
        </div>
      </div>
      {/* Group 4 */}
      <div>
        <div className="border-b border-gray-300 pb-2 mb-2 text-sm font-semibold">Lounge furniture</div>
        <div className="grid grid-cols-3 gap-4">
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: Sofa2seatIcon, svgLength: 1.5, svgHeight: 1, name: "2 seat sofa" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={Sofa2seatIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">2 seat sofa</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: Sofa3seatIcon, svgLength: 2, svgHeight: 1, name: "3 seat sofa" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={Sofa3seatIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">3 seat sofa</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: ModularSofaIcon, svgLength: 2.4, svgHeight: 2.136, name: "Modular sofa" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={ModularSofaIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Modular sofa</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: ChaiseSofaIcon, svgLength: 1.8, svgHeight: 0.867, name: "Chaise sofa" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={ChaiseSofaIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Chaise sofa</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: LoungeChair1Icon, svgLength: 0.699, svgHeight: 0.703, name: "Lounge chair" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={LoungeChair1Icon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Lounge chair</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: LoungeChair2Icon, svgLength: 0.801, svgHeight: 0.743, name: "Lounge chair" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={LoungeChair2Icon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Lounge chair</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: ArmChairIcon, svgLength: 0.6, svgHeight: 0.614, name: "Armchair" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={ArmChairIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Armchair</div>
          </div>

          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: OctagonalCoffTabIcon, svgLength: 0.9, svgHeight: 0.884, name: "Octagonal coffee table" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={OctagonalCoffTabIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Octagonal coffee table</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: PentagonalCoffTabIcon, svgLength: 0.946, svgHeight: 0.939, name: "Pentagonal coffee table" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={PentagonalCoffTabIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Pentagonal coffee table</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: HexagonalCoffTabIcon, svgLength: 1.039, svgHeight: 0.906, name: "Hexagonal coffee table" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={HexagonalCoffTabIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Hexagonal coffee table</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: OrganicCoffTabIcon, svgLength: 1.092, svgHeight: 0.771, name: "Organic coffee table" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={OrganicCoffTabIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Organic coffee table</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: OvalCoffTabIcon, svgLength: 1.2, svgHeight: 0.56, name: "Oval coffee table" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={OvalCoffTabIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Oval coffee table</div>
          </div>
        </div>
      </div>
      {/* Group 4 */}
      <div>
        <div className="border-b border-gray-300 pb-2 mb-2 text-sm font-semibold">Dining room</div>
        <div className="grid grid-cols-3 gap-4">
       {/*    <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: Chair6TableIcon, svgLength: 1.913, svgHeight: 1.8, name: "6 chair table" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={Chair6TableIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">6 chair table</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: Chair4TableIcon, svgLength: 2.051, svgHeight: 1.4, name: "4 chair table" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={Chair4TableIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">4 chair table</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: Chair2TableIcon, svgLength: 1.934, svgHeight: 0.9, name: "2 chair table" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={Chair2TableIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">2 chair table</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: RoundTableIcon, svgLength: 2.4, svgHeight: 2.284, name: "Round table" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={RoundTableIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Round table</div>
          </div> */}
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: ChairIcon, svgLength: 0.5, svgHeight: 0.403, name: "Chair" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={ChairIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Chair</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: ArmChairDRIcon, svgLength: 0.450, svgHeight: 0.450, name: "Armchair" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={ArmChairDRIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Armchair</div>
          </div>
        </div>
      </div>
      {/* Group 4 */}
      <div>
        <div className="border-b border-gray-300 pb-2 mb-2 text-sm font-semibold">Bathroom</div>
        <div className="grid grid-cols-3 gap-4">
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: BasinIcon, svgLength: 0.6, svgHeight: 0.663, name: "Basin" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={BasinIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Basin</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: BathtubIcon, svgLength: 1.828, svgHeight: 1.1496, name: "Bathtub" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={BathtubIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Bathtub</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: ToiletIcon, svgLength: 0.73, svgHeight: 0.458, name: "Toilet" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={ToiletIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Toilet</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: ShowerIcon, svgLength: 0.9, svgHeight: 0.9, name: "Shower" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={ShowerIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Shower</div>
          </div>
        </div>
      </div>
      {/* Group 4 */}
      <div>
        <div className="border-b border-gray-300 pb-2 mb-2 text-sm font-semibold">Bedroom</div>
        <div className="grid grid-cols-3 gap-4">
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: KingBedIcon, svgLength: 2.1, svgHeight: 1.736, name: "King bed" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={KingBedIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">King bed</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: SingleBedIcon, svgLength: 2.1, svgHeight: 1.53, name: "Single bed" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={SingleBedIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Single bed</div>
          </div>

        </div>
      </div>
      {/* Group 4 */}
      <div>
        <div className="border-b border-gray-300 pb-2 mb-2 text-sm font-semibold">Symbols & signs</div>
        <div className="grid grid-cols-3 gap-4">
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: SpotlightIcon, svgLength: 0.2, svgHeight: 0.5, name: "Spotlight" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={SpotlightIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Spotlight</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: LightFixIcon, svgLength: 0.2, svgHeight: 0.5, name: "Lighting fixture" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={LightFixIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Lighting fixture</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: Curtain1Icon, svgLength: 1, svgHeight: 0.2, name: "Curtain" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={Curtain1Icon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Curtain</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: Curtain2Icon, svgLength: 1, svgHeight: 0.1, name: "Curtain" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={Curtain2Icon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Curtain</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: "", svgLength: 0.07, svgHeight: 0.2, name: "Power outlet" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={NoSVGIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Power outlet</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: "", svgLength: 1, svgHeight: 0.35, name: "North sign" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={NoSVGIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">North sign</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: Hanger1Icon, svgLength: 0.782, svgHeight: 0.521, name: "Hangers" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={Hanger1Icon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Hangers</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: Hanger2Icon, svgLength: 1.044, svgHeight: 0.438, name: "Hangers" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={Hanger2Icon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Hangers</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: Hanger3Icon, svgLength: 1.304, svgHeight: 0.438, name: "Hangers" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={Hanger3Icon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Hangers</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: Hanger4Icon, svgLength: 1.566, svgHeight: 0.323, name: "Hangers" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={Hanger4Icon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Hangers</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: PlantIcon, svgLength: 0.712, svgHeight: 0.689, name: "Plant" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={PlantIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Plant</div>
          </div>



        </div>
      </div>
      {/* Group 4 */}
      <div>
        <div className="border-b border-gray-300 pb-2 mb-2 text-sm font-semibold">Shapes</div>
        <div className="grid grid-cols-3 gap-4">
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("TriangleSymbol")}
          > 
             <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={TriangleIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Triangle</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("CircleSymbol")}
          > 
             <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={CircleIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Circle</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SquareSymbol")}
          > 
             <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={SquareIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Square</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: OctagonalCoffTabIcon, svgLength: 0.9, svgHeight: 0.884, name: "Octagon" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={OctagonalCoffTabIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Octagon </div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: PentagonalCoffTabIcon, svgLength: 0.946, svgHeight: 0.939, name: "Pentagon" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={PentagonalCoffTabIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Pentagon</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: HexagonalCoffTabIcon, svgLength: 1.039, svgHeight: 0.906, name: "Hexagon" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={HexagonalCoffTabIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Hexagon</div>
          </div>
         {/*  <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: "", svgLength: 2, svgHeight: 2, name: "Arrow" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={ArrowIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Arrow</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: DoubbleArrowIcon, svgLength: 2, svgHeight: 0.5, name: "Double arrow" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={NoSVGIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Double arrow</div>
          </div> */}


        </div>
      </div>
      {/* Group 4 */}
     {/*  <div>
        <div className="border-b border-gray-300 pb-2 mb-2 text-sm font-semibold">Household appliances</div>
        <div className="grid grid-cols-3 gap-4">
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: "", svgLength: 0.6, svgHeight: 0.6, name: "Dish washer" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={NoSVGIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Dish washer</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: "", svgLength: 0.6, svgHeight: 0.6, name: "Washing machine" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={NoSVGIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Washing machine</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: "", svgLength: 0.6, svgHeight: 0.6, name: "Dryer" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={NoSVGIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Dryer</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: "", svgLength: 0.6, svgHeight: 0.6, name: "Oven" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={NoSVGIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Refrigerator</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: "", svgLength: 0.6, svgHeight: 0.6, name: "Freezer" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={NoSVGIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Freezer</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: "", svgLength: 0.6, svgHeight: 0.6, name: "Refrigerator / freezer" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={NoSVGIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Refrigerator / freezer</div>
          </div>


          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: "", svgLength: 0.6, svgHeight: 0.6, name: "Wardrode" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={NoSVGIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Wardrode</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: "", svgLength: 0.6, svgHeight: 0.6, name: "Pantry" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={NoSVGIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Pantry</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: "", svgLength: 0.6, svgHeight: 0.6, name: "Cleaning cabinet" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={NoSVGIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Cleaning cabinet</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: "", svgLength: 0.6, svgHeight: 0.5, name: "Stove" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={NoSVGIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Stove</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: "", svgLength: 0.6, svgHeight: 0.65, name: "Sink" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={NoSVGIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Stove</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("SvgSymbol", { svgPath: "", svgLength: 0.6, svgHeight: 0.4, name: "Microwave" })}
          >
            <div className="w-12 h-12 bg-gray-300 rounded">

              <img src={NoSVGIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Sink</div>
          </div>

        </div>
      </div> */}
      {/* Group 4 */}
     {/*  <div>
        <div className="border-b border-gray-300 pb-2 mb-2 text-sm font-semibold">Other</div>
        <div className="grid grid-cols-3 gap-4">
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("Icon 7")}
          >
            <div className="w-12 h-12 bg-gray-300 rounded"></div>
            <div className="text-xs mt-1">Icon 7</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("Icon 8")}
          >
            <div className="w-12 h-12 bg-gray-300 rounded"></div>
            <div className="text-xs mt-1">Icon 8</div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => handleIconClick("TextSymbol")}
          >
            <div className="w-12 h-12 bg-gray-300 rounded flex justify-center items-center">
              <img src={NoSVGIcon} alt="Icon" className="w-full h-full object-contain" />
            </div>
            <div className="text-xs mt-1">Text</div>
          </div>
        </div>
      </div> */}
    </div>

  );
});