/* eslint-disable react/no-unknown-property */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { WallType, WindowType } from "../../types/wallTypes";
import DraggableObject from "./DraggableObject";
import { BoundingBox, SelectableSymbol } from "./SelectableSymbol";
import { FloorplannerStoreContext } from '../../store/floorplannerStore';
import AttachableSymbol from "./AttachableSymbol";
import { Line } from "@react-three/drei";
import * as THREE from "three";
import { editorStore } from "../../store/editorStore";
import { observer } from "mobx-react-lite";
import { set, transaction } from "mobx";
import { invalidate, useFrame, useThree } from "@react-three/fiber";

interface WindowProps {
  window: WindowType;
  attachedToWall?: WallType;
  onDragStart: (startPosition: [number, number]) => void;
  onDrag: (newPosition: [number, number]) => void;
  onDragEnd: (endPosition: [number, number]) => void;
}

const Window: React.FC<WindowProps> = observer(({
  window,
  attachedToWall,
  onDragStart,
  onDrag,
  onDragEnd,
}) => {
  const { gl, camera } = useThree();
  const floorplannerStore = React.useContext(FloorplannerStoreContext);
  const windowLength = window.windowLength || floorplannerStore.windowLength;
  const lineWeight = window.lineWeight || floorplannerStore.windowLineWeight;
  const sillLineWeight = lineWeight / 2;
  const color = window.lineColor || floorplannerStore.lineColor;
  const windowFrameWidth = window.windowFrameWidth || floorplannerStore.windowFrameWidth;
  const wallLineWeight = floorplannerStore.convertLineWeightToWorld(attachedToWall?.lineWeight || floorplannerStore.wallLineWeight);
  const wallLineColor = attachedToWall?.lineColor || floorplannerStore.lineColor;
  const wallWidth = attachedToWall?.wallWidth || floorplannerStore.wallWidth;
  const [isHovered, setIsHovered] = useState(false);
  const groupRef = useRef<THREE.Group>(null);
  const [isResizing, setIsResizing] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const noopRaycast = () => null;
  const [prevCameraZoom, setPrevCameraZoom] = useState(camera.zoom);

  useFrame(() => {
    if (camera.zoom !== prevCameraZoom) {
      setPrevCameraZoom(camera.zoom);
      invalidate(); // Trigger a re-render
    }
  });

  const calculateBoundingBox = (): BoundingBox => {
    let leftMost = -window.windowLength / 2;
    let rightMost = window.windowLength / 2;
    if (attachedToWall && wallWidth >= 0.2) {
      leftMost = Math.min(leftMost, -wallWidth / 2 - wallLineWeight);
      rightMost = Math.max(rightMost, wallWidth / 2 + wallLineWeight);
    }
    return {
      topLeft: [leftMost, window.windowWidth / 2],
      topRight: [rightMost, window.windowWidth / 2],
      bottomLeft: [leftMost, -window.windowWidth / 2],
      bottomRight: [rightMost, -window.windowWidth / 2],
      width: rightMost - leftMost,
      height: window.windowWidth,
      depth: 0.1,
    };
  };

  const customRotation = window.rotation ? window.rotation : 0;

  // Update groupRef in editorStore when it changes
  useEffect(() => {
    if (groupRef.current) {
      editorStore.updateGroupRef(window.id, groupRef.current);
    }
  }, [window.id, groupRef.current]);

  // Redraw when the symbol.selected changes
  useEffect(() => {
  }, [window.selected]);

  return (
    <DraggableObject
      position={[window.position.x, window.position.y]}
      onDragStart={() => {
        setIsDragging(true);
        onDragStart
      }}
      onDragEnd={() => {
        onDragEnd
        setIsDragging(false);
      }}
      onDrag={(newPosition) => {
        floorplannerStore.unSelectAll();
        onDrag(newPosition);
      }}
      selectable={true}
      attachmentId={window.id}
      attachmentType="windowAttachments"
      symbol={window}
    >
      <AttachableSymbol
        attachmentId={window.id}
        attachmentType="windowAttachments"
        onAttachment={() => { }}
        store={floorplannerStore}
      >
        <SelectableSymbol
          ref={groupRef}
          handleSize={floorplannerStore.symbolHandleSize}
          calculateBoundingBox={calculateBoundingBox}
          onResizeStart={() => setIsResizing(true)}
          onResize={(newWidth, newHeight, boundingBox, handle) => {
            const widthDiff = (newWidth - windowLength) / 2;
            //const heightDiff = (newHeight - window.windowWidth) / 2;
            const heightDiff = 0
            transaction(() => {
              floorplannerStore.updateSymbolProperty(window.id, "windowLength", newWidth);
              //floorplannerStore.updateSymbolProperty(window.id, "windowWidth", newHeight);
              let position = [window.position.x, window.position.y];
              if (handle === "topLeft") {
                position = [
                  window.position.x - widthDiff * Math.cos(window.rotation || 0) + heightDiff * Math.sin(window.rotation || 0),
                  window.position.y - widthDiff * Math.sin(window.rotation || 0) + heightDiff * Math.cos(window.rotation || 0)
                ];
              } else if (handle === "topRight") {
                position = [
                  window.position.x + widthDiff * Math.cos(window.rotation || 0) + heightDiff * Math.sin(window.rotation || 0),
                  window.position.y + widthDiff * Math.sin(window.rotation || 0) + heightDiff * Math.cos(window.rotation || 0)
                ];
              } else if (handle === "bottomLeft") {
                position = [
                  window.position.x - widthDiff * Math.cos(window.rotation || 0) - heightDiff * Math.sin(window.rotation || 0),
                  window.position.y - widthDiff * Math.sin(window.rotation || 0) - heightDiff * Math.cos(window.rotation || 0)
                ];
              } else if (handle === "bottomRight") {
                position = [
                  window.position.x + widthDiff * Math.cos(window.rotation || 0) - heightDiff * Math.sin(window.rotation || 0),
                  window.position.y + widthDiff * Math.sin(window.rotation || 0) - heightDiff * Math.cos(window.rotation || 0)
                ];
              }
              if (attachedToWall) {
                floorplannerStore.detachSymbolFromWall(window.id);
                floorplannerStore.updateSymbolProperty(window.id, "position", position);
                floorplannerStore.attachSymbolToWall(window.id, attachedToWall.id, [position[0], position[1]]);
              } else {
                floorplannerStore.updateSymbolProperty(window.id, "position", position);
              }

            });
          }}
          onResizeEnd={() => setIsResizing(false)}
          rotation={customRotation}
          symbol={window}
          onPointerOver={() => setIsHovered(true)}
          onPointerOut={() => setIsHovered(false)}
          isDragging={isDragging}
        >
          <group
            ref={groupRef}
            rotation={[0, 0, customRotation]}
          >
           
            {/* If window is attached to a wall */}
            {attachedToWall && (
              <>
                {/* close the endpoints by drawing a line mesh across the opening with the wall properties */}
                <mesh position={[-(windowLength / 2 + wallLineWeight / 2), 0, 0]}>
                  <boxGeometry args={[wallLineWeight, wallWidth + wallLineWeight, 0.001]} />
                  <meshStandardMaterial color={wallLineColor} />
                </mesh>
                <mesh position={[(windowLength / 2 + wallLineWeight / 2), 0, 0]}>
                  <boxGeometry args={[wallLineWeight, wallWidth + wallLineWeight, 0.001]} />
                  <meshStandardMaterial color={wallLineColor} />
                </mesh>
                {/* Two thin sill lines (using Line of drei), closing the wall gap for window using width of wall, but only if attachedWall is wider than 0.2 */}
                {(attachedToWall.wallWidth || floorplannerStore.wallWidth) >= 0.2 && (
                  <>
                    <Line
                      points={[
                        [windowLength / 2, wallWidth / 2, 0],
                        [-windowLength / 2, wallWidth / 2, 0]
                      ]}
                      color={wallLineColor}
                      lineWidth={sillLineWeight}
                    />
                    <Line
                      points={[
                        [windowLength / 2, -wallWidth / 2, 0],
                        [-windowLength / 2, -wallWidth / 2, 0]
                      ]}
                      color={wallLineColor}
                      lineWidth={sillLineWeight}
                    />
                  </>
                )}

              </>
            )}
            {/* Window */}

            {/* Left Frame (now horizontal frame) */}
            <Line
              points={[
                [-window.windowLength / 2, window.windowWidth / 2, 0],
                [-window.windowLength / 2, -window.windowWidth / 2, 0]
              ]}
              color={isHovered || window.selected ? 'blue' : color}
              lineWidth={lineWeight}
              {...(window.lineType === "dashed" && {
                dashed: true,
                dashSize: 0.15,
                gapSize: 0.1,
              })}
              raycast={noopRaycast}
            />
            {/* Additional line near the left frame */}
            <Line
              points={[
                [-window.windowLength / 2 + windowFrameWidth, window.windowWidth / 2, 0],
                [-window.windowLength / 2 + windowFrameWidth, -window.windowWidth / 2, 0]
              ]}
              color={isHovered || window.selected ? 'blue' : color}
              lineWidth={lineWeight}
              {...(window.lineType === "dashed" && {
                dashed: true,
                dashSize: 0.15,
                gapSize: 0.1,
              })}
              raycast={noopRaycast}
            />
            {/* Middle Frame, only if wider or equal to 0.1 */}
            {(attachedToWall && (attachedToWall.wallWidth || floorplannerStore.wallWidth) >= 0.1) && (
              <Line
                points={[
                  [-windowLength / 2 + windowFrameWidth, 0, 0],
                  [windowLength / 2 - windowFrameWidth, 0, 0]
                ]}
                color={isHovered || window.selected ? 'blue' : color}
                lineWidth={lineWeight}
                {...(window.lineType === "dashed" && {
                  dashed: true,
                  dashSize: 0.15,
                  gapSize: 0.1,
                })}
                raycast={noopRaycast}
              />
            )}
            {/* Right Frame (now horizontal frame) */}
            <Line
              points={[
                [window.windowLength / 2, window.windowWidth / 2, 0],
                [window.windowLength / 2, -window.windowWidth / 2, 0]
              ]}
              color={isHovered || window.selected ? 'blue' : color}
              lineWidth={lineWeight}
              {...(window.lineType === "dashed" && {
                dashed: true,
                dashSize: 0.15,
                gapSize: 0.1,
              })}
              raycast={noopRaycast}
            />
            {/* Additional line near the right frame */}
            <Line
              points={[
                [window.windowLength / 2 - windowFrameWidth, window.windowWidth / 2, 0],
                [window.windowLength / 2 - windowFrameWidth, -window.windowWidth / 2, 0]
              ]}
              color={isHovered || window.selected ? 'blue' : color}
              lineWidth={lineWeight}
              {...(window.lineType === "dashed" && {
                dashed: true,
                dashSize: 0.15,
                gapSize: 0.1,
              })}
              raycast={noopRaycast}
            />
            {/* Top Frame (horizontal) */}
            <Line
              points={[
                [-window.windowLength / 2, window.windowWidth / 2, 0],
                [window.windowLength / 2, window.windowWidth / 2, 0]
              ]}
              color={isHovered || window.selected ? 'blue' : color}
              lineWidth={lineWeight}
              {...(window.lineType === "dashed" && {
                dashed: true,
                dashSize: 0.15,
                gapSize: 0.1,
              })}
              raycast={noopRaycast}
            />
            {/* Bottom Frame */}
            <Line
              points={[
                [-window.windowLength / 2, -window.windowWidth / 2, 0],
                [window.windowLength / 2, -window.windowWidth / 2, 0]
              ]}
              color={isHovered || window.selected ? 'blue' : color}
              lineWidth={lineWeight}
              {...(window.lineType === "dashed" && {
                dashed: true,
                dashSize: 0.15,
                gapSize: 0.1,
              })}
              raycast={noopRaycast}
            />
            {/* Debug green box to visually see where 0,0 is */}
            {/* <mesh position={[0, 0, 0]} rotation={[0, 0, 0]}>
            <boxGeometry args={[0.01, 0.01, 0.01]} />
            <meshBasicMaterial color="green" />
            </mesh> */}
          </group>
        </SelectableSymbol>
      </AttachableSymbol>
    </DraggableObject>
  );
});

export default Window;
