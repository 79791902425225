import { Room } from "../../types/wallTypes";
import { calculatePolygonArea, calculatePolygonCentroid } from "./findRooms";
import { Text } from "@react-three/drei";
import { convertWorld } from "../../utils/conversions";
import { editorStore } from "../../store/editorStore";
import React, { useState, useEffect, useMemo } from "react";
import { reaction } from "mobx";

export const RoomLabels: React.FC<{ rooms: Room[] }> = React.memo(({ rooms }) => {
  const [zoomDerivedValues, setZoomDerivedValues] = useState(() => ({
    textFontSize: 0.115 / editorStore.zoomLevelDivisor(),
    textBoxPadding: 0.13 / editorStore.zoomLevelDivisor(),
    textBoxHeight: 0.16 / editorStore.zoomLevelDivisor(),
    textBoxWidth: 0.6 / editorStore.zoomLevelDivisor(),
  }));

  // Memoize room calculations to avoid recalculations on every render
  const memoizedRooms = useMemo(() => {
    return rooms.map((room) => {
      const areaWorldUnits = calculatePolygonArea(room);
      const centroid = calculatePolygonCentroid(room);
      return { areaWorldUnits, centroid };
    });
  }, [rooms]);

  useEffect(() => {
    // Update zoom-derived values only when zoomLevel changes
    const dispose = reaction(
      () => editorStore.zoomLevel,
      () => {
        setZoomDerivedValues({
          textFontSize: 0.115 / editorStore.zoomLevelDivisor(),
          textBoxPadding: 0.13 / editorStore.zoomLevelDivisor(),
          textBoxHeight: 0.16 / editorStore.zoomLevelDivisor(),
          textBoxWidth: 0.6 / editorStore.zoomLevelDivisor(),
        });
      }
    );
    return () => dispose(); // Cleanup reaction on unmount
  }, []);

  return (
    <group>
      {memoizedRooms.map((roomData, index) => (
        <group key={index} position={[roomData.centroid.x, roomData.centroid.y, 0.01]}>
          <mesh position={[0, 0, -0.01]}>
            <planeGeometry
              args={[
                zoomDerivedValues.textBoxWidth + zoomDerivedValues.textBoxPadding,
                zoomDerivedValues.textBoxHeight,
              ]}
            />
            <meshBasicMaterial color={"white"} />
          </mesh>
          <Text
            position={[0, 0, 0]}
            fontSize={zoomDerivedValues.textFontSize}
            color="#444444"
            fontWeight={300}
          >
            {`${convertWorld(roomData.areaWorldUnits).toLocaleString()} m² `}
          </Text>
        </group>
      ))}
    </group>
  );
});
