/* eslint-disable react/no-unknown-property */
import React, { useState, useRef, useEffect } from "react";
import { ThreeEvent, useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";
import { projectToWorld } from "./projectToWorld";
import { floorplannerStore, useFloorplannerStore } from '../../store/floorplannerStore';
import { transaction } from "mobx";
import { editorStore } from "../../store/editorStore";
import { symbol } from 'prop-types';
import { SymbolType } from "../../types/wallTypes";

interface DraggableObjectProps {
  position: [number, number];
  onDragStart: (startPosition: [number, number]) => void;
  onDragEnd: (endPosition: [number, number]) => void;
  onDrag: (newPosition: [number, number]) => void;
  selectable: boolean;
  rotation?: [number, number, number];
  children?: React.ReactNode;
  disableDrag?: boolean;
  attachmentType?: 'doorAttachments' | 'windowAttachments';
  attachmentId?: string;
  zIndex?: number;
  symbol?: SymbolType
}

/**
 * A draggable object that can be moved around the canvas.
 * 
 * @param position The position of the object.
 * @param onDragStart The function to call when the object starts dragging.
 * @param onDragEnd The function to call when the object stops dragging.
 * @param onDrag The function to call when the object is dragged.
 * @param selectable Whether the object is selectable.
 * @param rotation The rotation of the object.
 * @param children The children to render.
 * @param disableDrag Whether to disable dragging.
 * @param attachmentType The type of attachment.
 * @param attachmentId The ID of the attachment.
 * @returns The DraggableObject component.
 * 
 **/
const DraggableObject: React.FC<DraggableObjectProps> = ({
  position,
  onDragStart,
  onDragEnd,
  onDrag,
  rotation,
  selectable,
  children,
  disableDrag = false,
  attachmentType,
  attachmentId,
  zIndex = 0,
  symbol
}) => {
  const meshRef = useRef<THREE.Mesh>(null);
  const attachableRef = useRef<{ checkForAttachment: (fromPosition:[number, number]) => void }>(null);
  const [isHovered, setIsHovered] = useState(false);
  const dragging = useRef(false);
  const dragOffset = useRef<[number, number] | null>(null);
  const { camera, gl } = useThree();
  // We use a local position state for the dragging effect, then when the drag ends we update the store
  const [localPosition, setLocalPosition] = useState(position);
  const floorplannerStore = useFloorplannerStore();
  // Convert attachmentType to object type in store (e.g., 'doors', 'windows' or 'walls')
  let objectType = 'doors' as 'doors' | 'windows';
  if (attachmentType === 'doorAttachments') {
    objectType = 'doors';
  } else if (attachmentType === 'windowAttachments') {
    objectType = 'windows';
  }

  const handlePointerMove = (e: PointerEvent) => {
    if (disableDrag) return;
    if (dragging.current && dragOffset.current) {
      const [newX, newY] = projectToWorld(e.clientX, e.clientY, gl, camera);
      const [offsetX, offsetY] = dragOffset.current;
      onDrag([newX - offsetX, newY - offsetY]);
      setLocalPosition([newX - offsetX, newY - offsetY]);
      // Pausing MobX reactions until all updates are complete
      transaction(() => {
        // If we are dragging a wall or window, we need to check for any attachment first
        if (attachmentType === 'doorAttachments' || attachmentType === 'windowAttachments') {
          // check if the symbol is attached to a wall
          const attachedWall = floorplannerStore.symbolIsAttached(attachmentId || '');
          if (attachedWall) {
            // Detach the symbol from the wall if it is attached
            floorplannerStore.detachSymbolFromWall(attachmentId || '');
          }
        }
        // Trigger new attachment check in AttachableSymbol
        attachableRef.current?.checkForAttachment([newX - offsetX, newY - offsetY]);
        // If the object is not attached to a wall, update the position in the store
        // We have to update the position in store because it causes a re-render of the symbol while dragging and it will 
        // otherwise flicker when it is drawn twice in store position and then in the current dragged local position
        if (!floorplannerStore.symbolIsAttached(attachmentId || '')) {
          floorplannerStore.updateSymbolProperty(attachmentId || '', "position", [newX - offsetX, newY - offsetY]);
        }
      });
    }
  };

  const handlePointerUp = (e: PointerEvent) => {
    if (disableDrag) return;
    if (dragging.current && dragOffset.current) {
      const [newX, newY] = projectToWorld(e.clientX, e.clientY, gl, camera);
      const [offsetX, offsetY] = dragOffset.current;
      // If the object is not attached to a wall, update the position in the store
      if (!floorplannerStore.symbolIsAttached(attachmentId || '')) {
        floorplannerStore.updateSymbolProperty(attachmentId || '', "position", [newX - offsetX, newY - offsetY]);
      }
      onDragEnd([newX - offsetX, newY - offsetY]);
    }
    dragging.current = false;
    dragOffset.current = null;
    // Remove event listeners from the canvas
    gl.domElement.removeEventListener("pointermove", handlePointerMove);
    gl.domElement.removeEventListener("pointerup", handlePointerUp);
    floorplannerStore.setBlockDirty(false);
    floorplannerStore.setDirty();
  };

  const handlePointerDown = (e: ThreeEvent<PointerEvent>) => {
    if (disableDrag) return;
    e.stopPropagation();
    // Select the symbol again if it is selectable
    if (symbol && selectable && !symbol.selected) {
      editorStore.clearSelections();
      editorStore.addSelection(symbol);
      floorplannerStore.updateSymbolProperty(symbol.id, "selected", true);
    }
    dragging.current = true;
    const [worldX, worldY] = projectToWorld(e.clientX, e.clientY, gl, camera);
    const offsetX = worldX - position[0];
    const offsetY = worldY - position[1];
    dragOffset.current = [offsetX, offsetY];
    onDragStart([offsetX, offsetY]);

    // Attach event listeners to the canvas
    gl.domElement.addEventListener("pointermove", handlePointerMove);
    gl.domElement.addEventListener("pointerup", handlePointerUp);
    floorplannerStore.setBlockDirty(true);
  };

  useEffect(() => {
    if (disableDrag) {
      dragging.current = false;
      dragOffset.current = null;
      gl.domElement.removeEventListener("pointermove", handlePointerMove);
      gl.domElement.removeEventListener("pointerup", handlePointerUp);
      floorplannerStore.setBlockDirty(false);
    }
  }, [disableDrag, gl.domElement]);

  // Make sure that if the store have updated the position, we update the local position
  useEffect(() => {
    setLocalPosition(position);
  }, [position]);

  return (
    <mesh
      ref={meshRef}
      position={[localPosition[0], localPosition[1], zIndex]}
      rotation={rotation}
      onPointerDown={handlePointerDown}
      onPointerOver={() => setIsHovered(true)}
      onPointerOut={() => setIsHovered(false)}
    >
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child as React.ReactElement<any>, {
            ref: attachableRef as React.Ref<any>, // Type assertion to pass ref
          });
        }
        return child;
      })}
    </mesh>
  );
};

export default DraggableObject;
