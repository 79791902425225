import * as THREE from "three";

// Base type that contains the shared properties
export type SymbolType = {
  id: string;
  name?: string;
  type: 'door' |'doubleDoor' | 'window' | 'symbol' | 'circleStairs' | 'rectStairs' | 'square' | 'triangle' | 'circle' |'svg' | 'text' | 'area';
  position: THREE.Vector2;
  zIndex: number;
  clipWall?: boolean;
  rotation?: number;
  flipHorizontal?: boolean;
  flipVertical?: boolean;
  attachedTo?: string;
  attachedDistance?: number;
  lineType?: LineType;
  lineWidth?: number;
  lineWeight?: number;
  lineColor?: number;
  lineStyle?: number;
  fillColor?: number;
  hoverColor?: number;
  selectable?: boolean;
  selected?: boolean;
};

// DoorType extends SymbolType and adds its unique properties
export type DoorType = SymbolType & {
  //start: THREE.Vector2;
  //end: THREE.Vector2;
  doorWidth: number;
  openAngle: number;
  doorFrameWidth?: number;
  doorBladeThickness?: number;

};
// DoubleDoorType extends SymbolType and adds its unique properties
export type DoubleDoorType = SymbolType & {
  //start: THREE.Vector2;
  //end: THREE.Vector2;
  doubleDoorWidth: number;
  openAngle: number;
  doorFrameWidth?: number;
  doorBladeThickness?: number;
};
// WindowType extends SymbolType and adds its unique properties
export type WindowType = SymbolType & {
  windowWidth: number;
  windowLength: number;
  windowFrameWidth?: number;
};
// CircleStairsType extends SymbolType and adds its unique properties
export type CircleStairsType = SymbolType & {
  //start: THREE.Vector2;
  //end: THREE.Vector2;
  circleStairsWidth: number;
  openAngle: number;
  stairStepSize?: number;
};
// RectStairsType extends SymbolType and adds its unique properties
export type RectStairsType = SymbolType & {
  //start: THREE.Vector2;
  //end: THREE.Vector2;
  rectStairsWidth: number;
  rectStairsHeight: number;
  openAngle: number;
  stairStepSize?: number;
};

// SquareSymbolType extends SymbolType and adds its unique properties
export type SquareSymbolType = SymbolType & {
  //start: THREE.Vector2;
  //end: THREE.Vector2;
  squareWidth: number;
  squareHeight: number;
  openAngle: number;
}; 
// TriangleSymbolType extends SymbolType and adds its unique properties
export type TriangleSymbolType = SymbolType & {
  //start: THREE.Vector2;
  //end: THREE.Vector2;
  triangleWidth: number;
  triangleHeight: number;
  openAngle: number;
}; 
// circleSymbolType extends SymbolType and adds its unique properties
export type CircleSymbolType = SymbolType & {
  //start: THREE.Vector2;
  //end: THREE.Vector2;
  circleWidth: number;
  circleHeight: number;
  circleRadius: number;
  openAngle: number;
}; 
export type SvgType = SymbolType & {
  //start: THREE.Vector2;
  //end: THREE.Vector2;
  svgPath: string;
  svgLength: number;
  svgHeight: number;
  svgRatio?: number;
};
export type TextType = SymbolType & {
  //start: THREE.Vector2;
  //end: THREE.Vector2;
 
  textBoxWidth: number;
  textBoxHeight: number; 
  fontSize: number;
  fontStyle: string;
  fontWeight: string; // A numeric font weight, "normal", or "bold"
  lineHeight:number;
  text: string;
};
export type AreaSymbolType = SymbolType & {
  vertices: THREE.Vector2[];
};
// Type guard to check if the symbol is of type SymbolType
export function isSymbolType(symbol: any): symbol is SymbolType {
  return ['door', 'doubleDoor', 'window', 'symbol', 'circleStairs', 'rectStairs', 'square', 'triangle', 'circle', 'svg', 'text', 'area'].find((type) => (symbol as SymbolType).type === type) !== undefined;
}
// Type guard to check if the symbol is of type DoorType
export function isDoorType(symbol: any): symbol is DoorType {
  return symbol && (symbol as DoorType).type === 'door';
}
// Type guard to check if the symbol is of type DoubleDoorType
export function isDoubleDoorType(symbol: any): symbol is DoubleDoorType {
  return symbol && (symbol as DoubleDoorType).type === 'doubleDoor';
}
// Type guard to check if the symbol is of type WindowType
export function isWindowType(symbol: any): symbol is WindowType {
  return symbol && (symbol as WindowType).type === 'window';
}
// Type guard to check if the symbol is of type circleStairsType
export function isCircleStairsType(symbol: any): symbol is CircleStairsType {
  return symbol && (symbol as CircleStairsType).type === 'circleStairs';
}
// Type guard to check if the symbol is of type rectStairsType
export function isRectStairsType(symbol: any): symbol is RectStairsType {
  return symbol && (symbol as RectStairsType).type === 'rectStairs';
}

// Type guard to check if the symbol is of type squareSymbolType
export function isSquareSymbolType(symbol: any): symbol is SquareSymbolType {
  return symbol && (symbol as SquareSymbolType).type === 'square';
}
// Type guard to check if the symbol is of type triangleSymbolType
export function isTriangleSymbolType(symbol: any): symbol is TriangleSymbolType {
  return symbol && (symbol as TriangleSymbolType).type === 'triangle';
}
// Type guard to check if the symbol is of type circleSymbolType
export function isCircleSymbolType(symbol: any): symbol is CircleSymbolType {
  return symbol && (symbol as CircleStairsType).type === 'circle';
}
// Type guard to check if the symbol is of type SvgType
export function isSvgType(symbol: any): symbol is SvgType {
  return symbol && (symbol as SvgType).type === 'svg';
}
// Type guard to check if the symbol is of type SvgType
export function isTextType(symbol: any): symbol is TextType {
  return symbol && (symbol as TextType).type === 'text';
}
// Type guard to check if the object is of type WallType
export function isWallType(object: any): object is WallType {
  return object && (object as WallType).type === 'wall';
}
// Type guard to check if the object is of type SingleLineType
export function isSingleLineType(object: any): object is SingleLineType {
  return object && (object as SingleLineType).type === 'singleLine';
}
// Type guard to check if the object is of type RulerLineType
export function isRulerLineType(object: any): object is RulerLineType {
  return object && (object as RulerLineType).type === 'rulerLine';
}
export function isAreaSymbolType(symbol: any): symbol is AreaSymbolType {
  return symbol && (symbol as AreaSymbolType).type === 'area';
}
export type CutoutType = {
  positionFromStart: number;
  length: number;
  // appliesTo is a set of 'left', 'right', 'inner', 'auto-start', 'auto-end'
  appliesTo: Set<string>;
  belongsTo?: string;
};

export type ClippingPolygonType = {
  belongsTo: string;
  polygon: THREE.Vector2[];
  objectType: "wall" | "door" |"doubleDoor" | "window" | "symbol";
};

export enum LineType {
  solid = "solid",
  dashed = "dashed",
}

export const WallConnectionEnd = 10000;
export const WallConnectionStart = 0;

export type WallConnectionType = {
  id: string;
  sourcePosition: number;
  targetPosition: number;
};

export type SymbolAttachmentType = {
  symbolId: string;
  positionFromStart: number;
};

export type WallType = {
  id: string;
  name?: string;
  type: "wall";
  start: THREE.Vector2;
  end: THREE.Vector2;
  controlPoint?: THREE.Vector2;
  position?: [number, number];
  connections?: WallConnectionType[];
  symbolAttachments?: SymbolAttachmentType[];
  cutouts?: CutoutType[];
  lineType?: LineType | 'solid';
  lineWidth?: number;
  lineWeight?: number;
  wallWidth?: number;
  selectable?: boolean;
  selected?: boolean;
  lineColor?: number;
  fillColor?: number;
  hoverColor?: number;
  lineStyle?: string;
  lineForm?: "straight" | "arc";
  lastUpdate?: number;
};

export type SingleLineType = {
  id: string;
  name?: string;
  type: "singleLine";
  start: THREE.Vector2;
  end: THREE.Vector2;
  controlPoint?: THREE.Vector2;
  position?: [number, number];
  connections?: WallConnectionType[];
  lineType?: LineType;
  lineWeight?: number;
  selectable?: boolean;
  selected?: boolean;
  lineColor?: number;
  fillColor?: number;
  hoverColor?: number;
  lineStyle?: string;
  lineForm?: "straight" | "arc";
  lastUpdate?: number;
};
export type RulerLineType = {
  id: string;
  name?: string;
  type: "rulerLine";
  start: THREE.Vector2;
  end: THREE.Vector2;
  controlPoint?: THREE.Vector2;
  position?: [number, number];
  connections?: WallConnectionType[];
  lineType?: LineType;
  lineWeight?: number;
  selectable?: boolean;
  selected?: boolean;
  lineColor?: number;
  fillColor?: number;
  hoverColor?: number;
  lineStyle?: string;
  lineForm?: "straight" | "arc";
  lastUpdate?: number;
};
export interface Point {
  x: number;
  y: number;
}
export type LineSideType = "left" | "right" | "inner" | "endcap";

export type WallShapeType = {
  wall: WallType;
  paths: THREE.Vector2[];
  lineSide: LineSideType;
};

export type otherModifiedPath = {
  wallId: string;
  pathIndex: number;
  point: THREE.Vector2,
}


export type Graph = {
  [key: string]: Point[];
};

export type Room = Point[];

export type AlignmentLineType = {
  id: string;
  hit: Point;
  axis: "x" | "y";
  color: string;
  lineWidth: number;
  objectId: string;
};
