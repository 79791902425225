import { RulerLineType, SingleLineType, WallConnectionEnd, WallConnectionStart, WallType } from "../../types/wallTypes";
import * as THREE from 'three';
import { removeWallConnection } from "./removeWallConnection";
import { floorplannerStore } from '../../store/floorplannerStore';
import { editorStore } from "../../store/editorStore";
import { updateAlignmentLines } from "./updateAligmentLines";
import { renderStore } from "../../store/renderStore";

/**
 * Drag the whole wall handle, it will move the whole wall releasing any connections
 * @param source The object to drag
 * @param startOffsetX The start x offset of the wall
 * @param startOffsetY The start y offset of the wall
 * @param endOffsetX The end x offset of the wall
 * @param endOffsetY The end y offset of the wall
 * @param newX The new x position of the wall
 * @param newY The new y position of the wall
 * @param delta The delta vector
 * @returns boolean If the wall was snapped
 */
export const dragWallHandle = (
    source: WallType | SingleLineType | RulerLineType,
    startOffsetX: number,
    startOffsetY: number,
    endOffsetX: number,
    endOffsetY: number,
    newX: number,
    newY: number,
    delta: THREE.Vector2,
): boolean => {
    const object = floorplannerStore.findObjectId(source.id);
    if (!object) {
        return false;
    }
    let snapped = false;
    const deltaStart = new THREE.Vector2(
        newX - startOffsetX,
        newY - startOffsetY,
    ).sub(object.start);
    const newStart = object.start.clone().add(deltaStart);
    const deltaEnd = new THREE.Vector2(
        newX - endOffsetX,
        newY - endOffsetY,
    ).sub(object.end);
    const newEnd = object.end.clone().add(deltaEnd);
    let newControlPoint;
    if (object.controlPoint) {
        newControlPoint = new THREE.Vector2(
            object.controlPoint.x + deltaStart.x,
            object.controlPoint.y + deltaStart.y,
        );
        floorplannerStore.setObjectProperty(object.id, 'controlPoint', newControlPoint);
    }

    delta.x = deltaStart.x;
    delta.y = deltaStart.y;

    floorplannerStore.clearConnections(object.id,);

    let startSnapped = floorplannerStore.snapToOtherObjects(object.id, WallConnectionStart, newStart, true);
    let endSnapped = floorplannerStore.snapToOtherObjects(object.id, WallConnectionEnd, newEnd, true);
    if (!startSnapped && !endSnapped && !editorStore.isShiftPressed) {
        floorplannerStore.updateObjectPosition(object.id, newStart, newEnd);
        const updObject = floorplannerStore.findObjectId(object.id);
        if (updObject) {
            startSnapped = floorplannerStore.snapToAlignmentLines(object.id, WallConnectionStart, newStart);
            if (startSnapped) {
                const updObject = floorplannerStore.findObjectId(object.id);
                if (updObject) {
                    newEnd.x += updObject.start.x - newStart.x;
                    newEnd.y += updObject.start.y - newStart.y;
                    floorplannerStore.updateObjectPosition(object.id, updObject.start, newEnd);
                    updateAlignmentLines(object.id, updObject.start.x, updObject.start.y, "start");
                }
            } else {
                endSnapped = floorplannerStore.snapToAlignmentLines(object.id, WallConnectionEnd, newEnd);
                if (endSnapped) {
                    const updObject = floorplannerStore.findObjectId(object.id);
                    if (updObject) {
                        newStart.x += updObject.end.x - newEnd.x;
                        newStart.y += updObject.end.y - newEnd.y;
                        floorplannerStore.updateObjectPosition(object.id, newStart, updObject.end);
                        updateAlignmentLines(object.id, updObject.end.x, updObject.end.y, "end");
                    }
                }
            }
        }
    }

    if (!startSnapped && !endSnapped) {
        floorplannerStore.updateObjectPosition(object.id, newStart, newEnd);
    } else {
        const snappedObject = floorplannerStore.findObjectId(object.id);
        if (snappedObject) {
            if (startSnapped && !endSnapped) {
                //floorplannerStore.updateObjectPosition(object.id, snappedObject.start, newEnd);
            } else if (!startSnapped && endSnapped) {
                //floorplannerStore.updateObjectPosition(object.id, newStart, snappedObject.end);
            }
        }
    }
    return snapped;
}
