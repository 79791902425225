import React, { useRef, useState, useEffect, useCallback, useMemo } from "react";
import * as THREE from "three";
import { useThree } from "@react-three/fiber";
import DraggableObject from "./DraggableObject";
import { SvgType } from "../../types/wallTypes";
import { BoundingBox, SelectableSymbol } from './SelectableSymbol';
import { projectToWorld } from "./projectToWorld";
import { FloorplannerStoreContext } from "../../store/floorplannerStore";
import { Svg } from "./Svg";
import { editorStore } from "../../store/editorStore";
import { observer } from "mobx-react-lite";
import { set } from "mobx";
import { is } from "@react-three/fiber/dist/declarations/src/core/utils";
import LightFixIcon from '../../assets/symbols/belysning2.svg';
interface SvgSymbolProps {
  svg: SvgType;
  onDragStart: (svg: SvgType, offset: [number, number]) => void;
  onDrag: (newPosition: [number, number]) => void;
  onDragEnd: (endPosition: [number, number]) => void;
}

const SvgSymbol: React.FC<SvgSymbolProps> = observer(({
  svg,
  onDragStart,
  onDragEnd,
  onDrag,
}) => {
  const { gl, camera } = useThree();
  const groupRef = useRef<THREE.Group>(null);
  const floorplannerStore = React.useContext(FloorplannerStoreContext);

  const [svgLength, setSvgLength] = useState(svg.svgLength);
  const [svgHeight, setSvgHeight] = useState(svg.svgHeight);
  const aspectRatio = svg.svgRatio || 1;

  const flipX = svg.flipHorizontal ? -1 : 1;
  const flipY = svg.flipVertical ? -1 : 1;
  const lineColor = svg.lineColor || floorplannerStore.lineColor;
  const [pivot, setPivot] = useState<[number, number]>([0, 0]);
  const dragOffset = useRef({ x: 0, y: 0 });
  const svgLineWeight = 1.5;
  let lineWeight;
  
  if (svg.svgPath === LightFixIcon) {
      lineWeight = svgLineWeight; // Assign specific line weight for the LightFixIcon
  } else {
      lineWeight = 0.5; // Default line weight for other symbols
  }
  
  // Assign line weight to the store after determining the value
  floorplannerStore.symbolLineWeight = lineWeight;
  
  
  const lineWeightInWorld = useMemo(() => floorplannerStore.convertLineWeightToWorld(lineWeight), [lineWeight, floorplannerStore]);
  const [centerPosition] = useState<[number, number]>([0, 0]);
  const [isResizing, setIsResizing] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const calculateBoundingBox = useCallback((): BoundingBox => {
    if (!groupRef.current) return { topLeft: [0, 0], topRight: [0, 0], bottomLeft: [0, 0], bottomRight: [0, 0], width: 0, height: 0, depth: 0 };

    const tempObject = groupRef.current.clone();
    tempObject.rotation.set(0, 0, 0);

    const box = new THREE.Box3().setFromObject(tempObject);
    const width = box.max.x - box.min.x;
    const height = box.max.y - box.min.y;
    const adjustedWidth = width + lineWeightInWorld;
    const adjustedHeight = height + lineWeightInWorld;

    return {
      topLeft: [-width / 2 - lineWeightInWorld / 2, height / 2 + lineWeightInWorld / 2],
      topRight: [width / 2 + lineWeightInWorld / 2, height / 2 + lineWeightInWorld / 2],
      bottomLeft: [-width / 2 - lineWeightInWorld / 2, -height / 2 - lineWeightInWorld / 2],
      bottomRight: [width / 2 + lineWeightInWorld / 2, -height / 2 - lineWeightInWorld / 2],
      width: adjustedWidth,
      height: adjustedHeight,
      depth: 0,
    };
  }, [lineWeightInWorld, svgLength, svgHeight]);

  useEffect(() => {
    setSvgLength(svg.svgLength);
    setSvgHeight(svg.svgLength / aspectRatio);
  }, [svg.svgLength]);

  const onPointerDownHandle = (event: React.PointerEvent) => {
    event.stopPropagation();
    const [worldX, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);
    dragOffset.current = {
      x: worldX - (svgLength * 0.75 * flipX + svg.position.x),
      y: worldY - (svgHeight * 0.75 * flipY + svg.position.y),
    };
    gl.domElement.addEventListener("pointermove", onHandleDrag);
    gl.domElement.addEventListener("pointerup", onPointerUpHandle);
    floorplannerStore.setBlockDirty(true);
  };

  const onHandleDrag = (event: PointerEvent) => {
    const [worldX, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);
    const dx = (worldX - svg.position.x - dragOffset.current.x) * flipX;
    const newWidth = Math.max(0.1, svgLength + dx);
    const newHeight = newWidth / aspectRatio;

    setSvgLength(newWidth);
    setSvgHeight(newHeight);
  };

  const onPointerUpHandle = () => {
    gl.domElement.removeEventListener("pointermove", onHandleDrag);
    gl.domElement.removeEventListener("pointerup", onPointerUpHandle);
    floorplannerStore.setBlockDirty(false);
    floorplannerStore.setDirty();
  };

  useEffect(() => {
    editorStore.updateGroupRef(svg.id, groupRef.current);
  }, [groupRef.current]);

  // Redraw when the symbol.selected changes
  useEffect(() => {
  }, [svg.selected]);

  return (
    <DraggableObject
      position={[svg.position.x, svg.position.y]}
      onDragStart={(offset) => {
        setIsDragging(true);
        onDragStart(svg, offset)
      }}
      onDragEnd={(endPosition) => {
        onDragEnd(endPosition)
        setIsDragging(false);
      }}
      onDrag={onDrag}
      selectable={true}
      attachmentId={svg.id}
      attachmentType="doorAttachments"
      zIndex={svg.zIndex}
      symbol={svg}
    >
      <SelectableSymbol
        ref={groupRef}
        handleSize={floorplannerStore.symbolHandleSize}
        calculateBoundingBox={calculateBoundingBox}
        onResizeStart={() => setIsResizing(true)}
        onResize={(newWidth, newHeight, boundingBox, handle) => {
          const calculatedHeight = newWidth / aspectRatio;
          setSvgLength(newWidth);
          setSvgHeight(calculatedHeight);
          floorplannerStore.updateSymbolProperty(svg.id, "svgLength", newWidth);
          floorplannerStore.updateSymbolProperty(svg.id, "svgHeight", calculatedHeight);
        }}
        onResizeEnd={() => setIsResizing(false)}
        center={centerPosition}
        rotation={svg.rotation}
        symbol={svg}
        drawHandles={true}
        lockAspectRatio={true}
        isDragging={isDragging}
        onPointerOver={() => setIsHovered(true)}
        onPointerOut={() => setIsHovered(false)}
      >
        <group
          ref={groupRef}
          rotation={[0, 0, svg.rotation || 0]}
          position={[0, 0, -0.01]}
        >
          <Svg
          svgId= {svg.id}
            src={svg.svgPath}
            fillMaterial={{
              color: svg.fillColor || "",
            }}
            lineColor={isHovered || svg.selected ? "blue" : lineColor || "black"}
            lineWeight={lineWeight}
            width={svgLength}
            lineType={svg.lineType || "solid"}
          />
        </group>
      </SelectableSymbol>
    </DraggableObject>
  );
});

export default SvgSymbol;
